import React from 'react';
import { Box, FormControl, Select, MenuItem, IconButton, TextField } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import { useProfile } from 'context'; // Assuming context is in src/context/index.js

const FloatingBookingSelector = () => {
  const { spaces, setSpaces, bookingType, setBookingType } = useProfile();

  const handleSpacesChange = (event) => {
    setSpaces(event.target.value);
  };

  const handleBookingTypeChange = (event) => {
    setBookingType(event.target.value);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '8px',
        zIndex: 1000,
        transition: 'width 0.3s ease',
      }}
    >
      {/* Icon for number of persons (spaces) */}
      <IconButton>
        <PeopleIcon />
      </IconButton>
      <TextField
        id="spaces"
        type="number"
        value={spaces}
        onChange={handleSpacesChange}
        inputProps={{ min: 1 }}
        sx={{ width: '80px', mx: 1 }} // Adjust width and margin for spacing
        size="small"
      />

      {/* Icon for booking type */}
      <IconButton>
        <EventIcon />
      </IconButton>
      <FormControl sx={{ mx: 1 }} size="small">
        <Select
          id="booking-type"
          value={bookingType}
          onChange={handleBookingTypeChange}
          variant="outlined"
          sx={{ width: '180px' }}
        >
          <MenuItem value="HD">Hot Desk</MenuItem>
          <MenuItem value="MR">Meeting Room</MenuItem>
          <MenuItem value="PO">Office</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FloatingBookingSelector;
