import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getOffices, getStadistics } from 'api'
import { CLUB_SEARCH_RANGE } from 'config/constants'

const DownActions = (pathname, office, stats, clubRange, setClubRange, role) => {
  useEffect(() => {}, [office])  
  return ''
}

DownActions.propTypes = {
  pathname: PropTypes.string,
  office: PropTypes.object,
  clubRange: PropTypes.object,
  stats: PropTypes.object,
  role: PropTypes.role,
}

DownActions.defaultProps = {
  pathname: '',
  office: {},
  clubRange: {},
  stats: {},
  role: '',
}

function Dashboard({ office, role }) {
  const [clubRange, setClubRange] = useState(CLUB_SEARCH_RANGE.this)
  const [stats, setStats] = useState([])
  const { t } = useTranslation()
  const { pathname } = useLocation()

  function getStats() {
    const query = {
      club: clubRange === CLUB_SEARCH_RANGE.this ? office.slug : CLUB_SEARCH_RANGE.all,
      year: new Date().getFullYear() - 1,
      month: new Date().getMonth() + 1,
      monthLimit: 12,
    }   
  }
  useEffect(() => {
    if (role !== 'referral') getStats()
  }, [office, clubRange])

  // TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('cards_statistics')
      ? { id: routes.dashboard, to: routes.dashboard, label: t('dashboard.cards') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <></>
  )
}

Dashboard.propTypes = {
  office: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
}

export default Dashboard
