import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Grid, Typography, CardMedia } from '@mui/material'
import { API_BASE_URL } from '../../../../config/api'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const MemberDetail = ({ member, goBack }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  function isValidURL(url) {
    const pattern = /^((https?|ftp):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return pattern.test(url);
  }

  function openWebsite(url) {
    if (url) {
      if (isValidURL(url)) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'https://' + url;
        }
      }
      window.open(url, '_blank');
    }
  }

  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12} style={{alignContent: 'flex-end', justifyContent: 'flex-end'}}>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
        </Grid>  
        <Grid item xs={3}>
          <CardMedia
            component="img"
            src={member?.user?.avatar ? `${API_BASE_URL}${member?.user?.avatar}` : noAvatar}
            alt="Participant Image"
            onError={handleImageRenderError}
            sx={{ width: '15vw', borderRadius: '10px' }}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h4" gutterBottom component="div">
            {member?.user?.first_name} {member?.user?.last_name}
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom component="div">
            {member?.user?.alias}
          </Typography>
          <br />
          <Typography>
            {member?.user?.description}
          </Typography>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-start" sx={{marginTop: '10px'}}>

                {member?.user?.link_1 && <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="button_color"
                    fullWidth
                    onClick={() => openWebsite(member?.user?.link_1)}
                  >Contact Me
                  </Button>
                </Grid>}
                {member?.user?.link_2 && <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="button_color"
                    fullWidth
                    onClick={() => openWebsite(member?.user?.link_2)}
                  >What I do
                  </Button>
                </Grid>}

              </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

MemberDetail.propTypes = {
  member: PropTypes.object,
}

export default MemberDetail
