import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import FilterListIcon from '@mui/icons-material/FilterList';
import { ModalFooter, ContainerStyle, ImageStyle, ModalContent } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import EventOnboarding from '../eventOnboarding/view'
import { eventTicketAction, getPublicEvent } from 'api'
import { Chip, Switch, Button, Backdrop, Fade, Divider, Grid, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar, } from '@mui/material'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { API_BASE_URL } from '../../../../config/api'
import { set } from 'date-fns'
import ModalCloseButton from '../../../atoms/modalCloseButton/view'

const EventModalParticipant = ({ selectedParticipant, myTicket, onClose, visibleModal }) => {

  const { t } = useTranslation()
  const [actionSelected, setActionSelected] = useState(false)
  const [ticketAction, setTicketAction] = useState({
    origin: myTicket?.slug,
    target: selectedParticipant?.slug,
    type: 'VT',
    value: 'vote',
  })

  function updateAction() {
    eventTicketAction(ticketAction).then(r => {
      setActionSelected(!actionSelected)
    }).catch(error => console.log('The error is -> ', error))
  }

  function isValidURL(url) {
    const pattern = /^((https?|ftp):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return pattern.test(url);
  }

  function openWebsite(url) {
    if (url) {
      if (isValidURL(url)) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'https://' + url;
        }
      }
      window.open(url, '_blank');
    }
  }

  useEffect(() => {
    setTicketAction({ ...ticketAction, origin: myTicket?.slug, target: selectedParticipant?.slug })
    const actionFound = selectedParticipant?.actions.some(action => {
      return myTicket?.slug === action.origin && selectedParticipant?.slug === action.target;
    }) || false
    setActionSelected(actionFound)
  }, [visibleModal])

  const eventImage = selectedParticipant?.user?.avatar ? `${API_BASE_URL}${selectedParticipant?.user?.avatar}` : 'https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg'

  return (
    <Modal modal
      open={visibleModal}
      onClose={() => onClose()}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >

      <ModalContent>
        <ModalCloseButton onClick={() => onClose()} />        
        <Fade in={visibleModal}>
          <div>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="User Avatar" src={eventImage}
                    style={{
                      width: 100, // Adjust the width and height as needed
                      height: 100, // Adjust the width and height as needed
                      marginRight: 10,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${selectedParticipant?.user?.first_name} ${selectedParticipant?.user?.last_name}`}
                  secondary={selectedParticipant?.user?.alias}
                />
              </ListItem>

              {myTicket == 'imposible' && <Box display="flex" alignItems="center">                
                <Typography variant="body2" style={{ marginLeft: '15px' }} color="primary">Great Chat!</Typography>
                <Switch color="secondary" onChange={updateAction} checked={actionSelected} />
                <Divider style={{ flexGrow: 1 }} />
              </Box>}

              <ListItem>
                <ListItemText primary="About Me" secondary={selectedParticipant?.user?.description} />
              </ListItem>
              {selectedParticipant?.needs?.length > 0 && <ListItem>
                <ListItemText primary="Happy to learn about" secondary={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      flexWrap: 'wrap',
                      '& > *': {
                        marginBottom: 10,
                      },
                    }}
                  >
                    {selectedParticipant?.needs?.map((item) => {
                      return <Chip label={item} color="primary" variant="outlined" style={{ marginBottom: 5 }} />
                    })}
                  </Stack>
                } />
              </ListItem>}
              {selectedParticipant?.offers?.length > 0 && <ListItem>
                <ListItemText primary="Happy to share my knowledge in" secondary={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      flexWrap: 'wrap',
                      '& > *': {
                        marginBottom: 10,
                      },
                    }}
                  >
                    {selectedParticipant?.offers?.map((item) => {
                      return <Chip label={item} color="primary" variant="outlined" style={{ marginBottom: 5 }} />
                    })}
                  </Stack>
                } />
              </ListItem>}
            </List>
            <ModalFooter>
              <Grid container spacing={1} alignItems="center" justifyContent="center">

                {selectedParticipant?.link_1 && <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="button_color"
                    fullWidth
                    onClick={() => openWebsite(selectedParticipant?.link_1)}
                  >Contact Me
                  </Button>
                </Grid>}
                {selectedParticipant?.link_2 && <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="button_color"
                    fullWidth
                    onClick={() => openWebsite(selectedParticipant?.link_2)}
                  >What I do
                  </Button>
                </Grid>}

              </Grid>
            </ModalFooter>
          </div>
        </Fade>
      </ModalContent>
    </Modal>
  )
}

EventModalParticipant.propTypes = {
  selectedParticipant: PropTypes.object,
  myTicket: PropTypes.object,
  onClose: PropTypes.func,
  visibleModal: PropTypes.bool,
}
EventModalParticipant.defaultProps = {
  selectedParticipant: {},
  myTicket: {},
  onClose: () => { },
  visibleModal: false,
}

export default EventModalParticipant
