import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { getSearchMap } from 'api';
import { Card, Typography, CardMedia, CardContent } from '@mui/material';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'config/keys';
import { API_BASE_URL } from 'config/api';
import noAvatar from 'assets/images/no-avatar.png';
import FloatingBookingSelector from 'components/organisms/floatingBookingSelector/view'
import { useProfile } from 'context';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'; // Import dayjs

const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: 51.52268795372802,
  lng: -0.08518920377573923,
};

const BookingFind = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [coworkingSpaces, setCoworkingSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs()); 
  const [postcode, setPostcode] = useState('');
  const [coordinates, setCoordinates] = useState(center); // Default coordinates
  const { spaces, setSpaces, bookingType, setBookingType } = useProfile();
  const navigate = useNavigate()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  // Function to fetch coordinates based on postcode
  const fetchCoordinates = async (postcode) => {
    console.log('Fetching coordinates for postcode:', postcode);
    console.log('API Key:', REACT_APP_GOOGLE_MAPS_API_KEY);
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setCoordinates({ lat, lng });
        return { lat, lng };
      } else {
        console.error('No results found for postcode.');
        return center; // Fallback to default center if no results
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return center; // Fallback to default center in case of error
    }
  };

  useEffect(() => {
    const fetchCoworkingSpaces = async () => {
      try {
        let lat = coordinates.lat;
        let lng = coordinates.lng;

        // Fetch new coordinates if postcode is provided
        if (postcode) {
          const coords = await fetchCoordinates(postcode);
          lat = coords.lat;
          lng = coords.lng;
        }

        const params = {
          start: selectedDate?.format('YYYY-MM-DD'),
          end: selectedDate?.format('YYYY-MM-DD'),
          spaces,
          latitude: lat,
          longitude: lng,
        };

        getSearchMap(params)
          .then((r) => {
            console.log('Coworking Spaces', r);
            setCoworkingSpaces(r);
          })
          .catch((error) => console.log('The error is -> ', error));
      } catch (error) {
        console.error('Error fetching coworking spaces:', error);
      }
    };

    fetchCoworkingSpaces();
  }, [searchQuery, selectedDate, spaces, postcode]);


useEffect(() => {
  if(selectedSpace) {
    navigate(`/venue/${selectedSpace.slug}`);
  }
  
},[selectedSpace])

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <CircularProgress />;

  return (
    <Grid container spacing={2}>
      {/* Left Side: Filters and Results */}
      <Grid item xs={4} sx={{marginTop:'40px'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select a date"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            renderInput={(params) => (
              <TextField {...params} fullWidth variant="outlined" margin="normal" />
            )}
          />
        </LocalizationProvider>

        

        <TextField
          label="Postcode"
          variant="outlined"
          fullWidth
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
          margin="normal"
        />

        <Grid container spacing={2}>
          {coworkingSpaces.map((space) => (
            <Grid item xs={6} key={space.slug}>
              <Card onClick={() => setSelectedSpace(space)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={space?.image ? `${API_BASE_URL}${space?.image}` : noAvatar} // Replace with actual image URL or a default image
                  alt={space.venue_name}
                />
                <CardContent>
                  <Typography variant="body1" component="div">
                    {space.venue_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Free spaces: {space.available_resources} of {space.total_resources}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Price: £{space.item_price}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Distance: {space.venue_distance.toFixed(2)} meters
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Right Side: Map */}
      <Grid item xs={8}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={12}
          center={coordinates} // Update map center to the fetched coordinates
        >
          {coworkingSpaces.map((space) => (
            <Marker
              key={space.id}
              position={{ lng: space?.venue_location[0], lat: space?.venue_location[1] }}
              onClick={() => setSelectedSpace(space)}
            />
          ))}
        </GoogleMap>
      </Grid>
      <FloatingBookingSelector />
    </Grid>
  );
};

export default BookingFind;
