import React, { createContext, useState, useContext } from 'react';

const Context = createContext({
  profile: null,
  setProfile: () => null,
  spaces: 0,
  setSpaces: () => null,
  bookingType: '',
  setBookingType: () => null,
  resetContext: () => null,
});

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [spaces, setSpaces] = useState(1);
  const [bookingType, setBookingType] = useState('HD');

  const resetContext = () => {
    setProfile(null);
    setSpaces(1);
    setBookingType('HD');
    // Reset other states if you have any
  };

  return (
    <Context.Provider value={{ profile, setProfile, spaces, setSpaces, bookingType, setBookingType, resetContext }}>
      {children}
    </Context.Provider>
  );
};

export const useProfile = () => {
  return useContext(Context);
};

export default Context;
