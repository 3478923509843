import React, {useState} from 'react';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Info, Group, Event, Business, Campaign, Settings } from '@mui/icons-material';
import { StyledAvatar, CommunityBasic, CommunityName, CommunityStatus, AvatarContainer, StyledListItem } from './styled';

const SideMenu = ({ open, onClose, changeTab, avatar, title, subtitle, role }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  console.log(role)
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    changeTab(index);
  };
  return (
    <Box
      sx={{
        //width: '100%',
        //maxWidth: 240,
        bgcolor: 'background.paper',
        //position: 'fixed',
        //height: '100vh',
        //overflow: 'auto',
      }}
    >
      <AvatarContainer>
        <StyledAvatar alt="Avatar" src={avatar} />
      </AvatarContainer>

      <CommunityBasic>
        <CommunityName>{title}</CommunityName>
        <CommunityStatus>{subtitle}</CommunityStatus>
      </CommunityBasic>
      <Divider />
      <List>
      <StyledListItem selected={selectedIndex === 0} key={'about_us'} onClick={() => handleListItemClick(0)}>
        <ListItemIcon><Info /></ListItemIcon>
        <ListItemText primary='About us' />
      </StyledListItem>
      <StyledListItem selected={selectedIndex === 1} key={'members'} onClick={() => handleListItemClick(1)}>
        <ListItemIcon><Group /></ListItemIcon>
        <ListItemText primary='Members' />
      </StyledListItem>
      <StyledListItem selected={selectedIndex === 2} key={'activity'} onClick={() => handleListItemClick(2)}>
        <ListItemIcon><Event /></ListItemIcon>
        <ListItemText primary='Activity' />
      </StyledListItem>
      {role === 'admin' && <StyledListItem selected={selectedIndex === 3} key={'partners'} onClick={() => handleListItemClick(3)}>
        <ListItemIcon><Business /></ListItemIcon>
        <ListItemText primary='Partners' />
      </StyledListItem>}
      {role === 'admin' && <StyledListItem selected={selectedIndex === 4} key={'marketing'} onClick={() => handleListItemClick(4)}>
        <ListItemIcon><Campaign /></ListItemIcon>
        <ListItemText primary='Marketing' />
      </StyledListItem>}
      {role === 'admin' && <StyledListItem selected={selectedIndex === 5} key={'manage'} onClick={() => handleListItemClick(5)}>
        <ListItemIcon><Settings /></ListItemIcon>
        <ListItemText primary='Manage' />
      </StyledListItem>}
      </List>
    </Box>
  );
};

export default SideMenu;
