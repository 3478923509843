import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export const NoResultsPlaceholder = ({ firstSentence, lastSentence }) => (
  <Box
  display="flex"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
  height="100%"
  textAlign="center"
  p={3}
>
  <SearchOffIcon style={{ fontSize: 80, color: 'grey' }} />
  <Typography variant="h5" color="textPrimary" gutterBottom>{firstSentence}</Typography>
  <Typography variant="body1" color="textSecondary">{lastSentence}</Typography>
</Box>
)

NoResultsPlaceholder.propTypes = {
  firstSentence: PropTypes.string.isRequired,
  lastSentence: PropTypes.string.isRequired,
}

NoResultsPlaceholder.defaultProps = {
  firstSentence: 'No results found',
  lastSentence: 'Try something different or wait a few minutes',
}

export default NoResultsPlaceholder
