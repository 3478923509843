import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  Container,
  SecondRowContainer,
  ModalContent,
  OverviewRight,
  OverviewLeft,
  OverviewTitle,
  OfferContainer,
  OpeningTimesContainer,
  LocationContainer,
  StyledBadge,
  StyledPickersDay
} from './styled'
import { SpacerVerticalSmall, FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import VenueHeader from '../../venue/venueHeader/view'
import EventList from '../../community/eventList/view'
import { getVenue, getVenuePrice, createBooking } from 'api'
import { TextField, InputLabel, Grid, Card, CardMedia, Button, FormControlLabel, Checkbox, ListItemText, Box, Modal } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import noAvatar from '../../../../assets/images/no-avatar.png'
import CircularProgress from '@mui/material/CircularProgress';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale to start the week on Monday


import Badge from '@mui/material/Badge';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';

import FloatingBookingSelector from 'components/organisms/floatingBookingSelector/view'

import ImageGallery from "react-image-gallery";
import { useProfile } from 'context';

import PhotoAlbum from "react-photo-album";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import 'react-image-gallery/styles/css/image-gallery.css';
import { set } from 'date-fns';

dayjs.locale('en-gb'); // Set the locale for dayjs

const images = [
  {
    original: "https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5703.jpg",
    thumbnail: "https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5703.jpg",
  },
  {
    original: "https://workersleague.com/wp-content/uploads/2019/06/Bonhill-Three-Meeting-Space-e1560334371262.jpg",
    thumbnail: "https://workersleague.com/wp-content/uploads/2019/06/Bonhill-Three-Meeting-Space-e1560334371262.jpg",
  },
  {
    original: "https://workersleague.com/wp-content/uploads/2019/06/Bonhill-Three-Sofas-e1560334432605.jpg",
    thumbnail: "https://workersleague.com/wp-content/uploads/2019/06/Bonhill-Three-Sofas-e1560334432605.jpg",
  },
  {
    original: "https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5702.jpg",
    thumbnail: "https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5702.jpg",
  },
  {
    original: "https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5703.jpg",
    thumbnail: "https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5703.jpg",
  }
];

const VenueSingle = () => {

  const openingHoursData = [
    { day: 'Monday', time: '9:00 AM - 6:00 PM' },
    { day: 'Tuesday', time: '9:00 AM - 6:00 PM' },
    { day: 'Wednesday', time: '9:00 AM - 6:00 PM' },
    { day: 'Thursday', time: '9:00 AM - 6:00 PM' },
    { day: 'Friday', time: '9:00 AM - 6:00 PM' },
    { day: 'Saturday', time: 'Closed' },
    { day: 'Sunday', time: 'Closed' },
  ];

  const { spaces, setSpaces, bookingType, setBookingType } = useProfile();

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [venue, setVenue] = useState(null)
  const [confirmModal, setConfirmModal] = useState(false)
  const [bookingPrice, setBookingPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0);
  const [bookingCode, setBookingCode] = useState(null)
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [center, setCenter] = useState(null)
  const [gallery, setGallery] = useState(null)
  const [sessionId, setSessionId] = useState(null)

  const [prices, setPrices] = useState([])

  const [value, setValue] = useState("0");
  const [selectedDate, setSelectedDate] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: '',
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: false,
  })

  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  const handleMonthChange = (date) => {
    //if (requestAbortController.current) {
    // make sure that you are aborting useless requests
    // because it is possible to switch between months pretty quickly
    //requestAbortController.current.abort();
    //}

    //setIsLoading(true);
    setHighlightedDays([]);
    //fetchHighlightedDays(date);
  };


  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;

    // Check if prices is an array
    if (!Array.isArray(prices)) {
      return null; // or handle it in a way that makes sense for your application
    }

    // Check if there is a price for the current day
    const priceInfo = prices?.find((price) => {
      const priceDate = new Date(price.start);
      return priceDate.toISOString().split('T')[0] === props.day.format('YYYY-MM-DD');
    });

    
    return (
      <StyledBadge
        overlap="circular"
        badgeContent={priceInfo && priceInfo.available >= spaces ? `£${priceInfo?.price * spaces}` : undefined}
      >
        <StyledPickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </StyledBadge>
    );
  }




  const handleInputChange = (event) => {
    if (event.target.name === 'name') setErrors({ ...errors, name: '' })
    if (event.target.name === 'email') setErrors({ ...errors, email: '' })
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const doBooking = () => {

    console.log('venueSlug', slug)
    const postData = {
      "bookings": [
        {
          number_spaces: spaces,
          booking_type: bookingType,
          venue: slug,
          start: selectedDate.format('YYYY-MM-DD'),
          price: totalPrice,
        }
      ]
    }
    createBooking(postData).then(r => {
      setSessionId(r.sessionId)
    })
      .catch(error => console.log('The error is -> ', error))

  };

  function getVenueInfo(firstLoad) {
    getVenue(slug).then(r => {
      const locationCenter = { lat: r?.location[0], lng: r?.location[1] }
      setCenter(locationCenter)
      setVenue(r)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function getVenuePricesInfo(firstLoad) {
    getVenuePrice(slug).then(r => {
      setPrices(r.prices)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  useEffect(() => {
    if (selectedDate) {
        const formattedDate = selectedDate.format('YYYY-MM-DD');

        // Find the price for the selected date
        const priceEntry = prices.find(price => {
            const startDate = new Date(price.start).toISOString().split('T')[0];
            return startDate === formattedDate;
        });

        // If available spaces are less than selected spaces, reset prices
        if (priceEntry && priceEntry.available < spaces) {
            setBookingPrice(0);
            setTotalPrice(0);
        } else {
            const dailyPrice = priceEntry ? parseFloat(priceEntry.price) : 0;
            setBookingPrice(dailyPrice);
            setTotalPrice(dailyPrice * spaces);
        }
    } else {
        setBookingPrice(0);
        setTotalPrice(0);
    }
}, [spaces, bookingType, selectedDate, prices]);

  useEffect(() => {
    getVenueInfo(true)
    getVenuePricesInfo(true)
  }, [])

  useEffect(() => {
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])


  const avatar = venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar
  const background = venue?.background ? `${API_BASE_URL}${venue?.background}` : noAvatar

  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <VenueHeader title={venue?.name} subtitle={venue?.single_address} avatar={avatar} background={background} />
      <Grid container>
        <Grid item xs={12}>

          <SecondRowContainer>

            <div>
              {/* Tab content sections */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <OverviewLeft>
                    <OverviewTitle>Upcoming activity</OverviewTitle>
                    <OverviewTitle>About us</OverviewTitle>
                    <div dangerouslySetInnerHTML={{ __html: venue?.long_description }} />
                    <div>
                      <ImageGallery items={images} />
                    </div>

                    <LocationContainer>
                      <OverviewTitle>Location</OverviewTitle>
                      <Typography color="textSecondary" sx={{ fontSize: '14px', marginBottom: '16px' }}>
                        {venue?.single_address}
                      </Typography>
                      <div style={{ height: '200px', width: '100%' }}>
                        {/* Embed Google Maps with the location coordinates */}
                        <iframe
                          title="The Workers' League"
                          width="100%"
                          height="100%"
                          style={{ border: 0 }}
                          allowFullScreen
                          src="https://www.google.com/maps/embed/v1/place?q=The+Workers'+League+Bonhill+Street&key=AIzaSyDTijFIM_ZWjk3TOm3Ia2a4PLZhUZLTfKo"
                        />
                      </div>
                    </LocationContainer>

                    <OpeningTimesContainer>
                      <OverviewTitle>Opening Times</OverviewTitle>
                      {openingHoursData.map(({ day, time }) => (
                        <div key={day} style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '0.9rem' }}>{day}</Typography>
                          <Typography sx={{ fontSize: '0.9rem', color: '#666' }}>{time}</Typography>
                        </div>
                      ))}
                    </OpeningTimesContainer>

                    <OpeningTimesContainer>
                      <OverviewTitle>Transparency</OverviewTitle>
                      <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>Referral fees</Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: '#666', fontWeight: 'bold' }}>£0 per booking.</Typography>
                      </div>
                      <div key={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>Data protection</Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: '#666', fontWeight: 'bold' }}>No data shared.</Typography>
                      </div>
                      <div key={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>Cancellation policy</Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: '#666', fontWeight: 'bold' }}>Cancel any time. Full refund.</Typography>
                      </div>
                    </OpeningTimesContainer>
                  </OverviewLeft>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <OverviewRight>
                    <Grid item xs={12} md={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DateCalendar
                          value={selectedDate}
                          loading={isLoading}
                          onMonthChange={handleMonthChange}
                          renderLoading={() => <DayCalendarSkeleton />}
                          slots={{
                            day: ServerDay,
                          }}
                          slotProps={{
                            day: {
                              highlightedDays,
                            },
                          }}
                          onChange={(newValue) => setSelectedDate(newValue)}
                        />
                      </LocalizationProvider>
                      {/* Booking Info Display */}
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">
                          You are booking {spaces} {bookingType === 'HD' ? 'Hot Desk' : 'Meeting Room'}{spaces > 1 ? 's' : ''} for £{totalPrice}
                        </Typography>
                      </Box>

                      <Button variant='contained' color='button_color' onClick={() => doBooking()}>Book</Button>
                    </Grid>



                  </OverviewRight>
                </Grid>
              </Grid>




            </div>
          </SecondRowContainer>
        </Grid>

      </Grid>
      <FloatingBookingSelector />
      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>

          <Typography id="parent-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Confirm your booking
          </Typography>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ position: 'relative' }} onClick={() => setConfirmModal(true)}>
              <CardMedia
                component="img"
                src={venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar}
                alt="Venue Image"
                onError={handleImageRenderError}
                sx={{ borderRadius: '10px', height: '150px' }}
              />

              <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '10px', marginTop: '20px' }}>
                Please confirm your booking details below and your contact information.
              </Typography>
              <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={3}>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Date:</Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Price:</Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Venue:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    {selectedDate}
                  </Typography>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    £{bookingPrice}
                  </Typography>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    {venue?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Box container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    size='small'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    size='small'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormItemColumn style={{ marginTop: '0px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size='small'
                          checked={formData.privacyPolicyChecked}
                          onChange={(e) => handleCheckboxChange(e, 'privacyPolicyChecked')}
                          name="privacyPolicyChecked"
                          color="primary"

                        />
                      }
                      error={!!errors.privacyPolicyChecked}
                      helperText={errors.privacyPolicyChecked}
                      label={
                        <span style={{ fontSize: '12px' }}>
                          I accept the{' '}
                          <a href="https://the-epic.space/privacy" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                          </a>
                        </span>
                      }
                    />
                  </FormItemColumn>
                </Grid>
              </Box>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button size='small' disabled={!formData.privacyPolicyChecked} variant='contained' color='secondary' onClick={() => doBooking()} sx={{ alignItems: 'center' }}>
                  Confirm booking
                </Button>
              </div>

            </div>

          </Grid>

        </ModalContent>
      </Modal>
    </Container>
  )
}

VenueSingle.propTypes = {}

export default VenueSingle
