import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import EventList from '../eventList/view'
import PropTypes from 'prop-types'


const Activity = props => {
    const { community, upcomingEvents, pastEvents, setCommunityJoin } = props

    return (
        <div>
            <EventList community={community}  setCommunityJoin={setCommunityJoin}/>
        </div>

    )
}

Activity.propTypes = {
    community: PropTypes.object,
    upcomingEvents: PropTypes.array,
    pastEvents: PropTypes.array,
    setCommunityJoin: PropTypes.func
}

export default Activity
