import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import ProfileHeader from '../profileHeader/view'
import { getProfile, updateProfile } from 'api'
import { TextField, FormControlLabel, Switch, FormGroup, Typography, Button, FormControl, Avatar, Grid, Backdrop, CircularProgress } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import noAvatar from '../../../../assets/images/no-avatar.png'
import { API_BASE_URL } from 'config/api';
import coworking from 'assets/images/coworking.jpg'
const Profile = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    alias: '',
    avatar: '',
    background: '',
    link_1: '',
    link_2: '',
    link_3: '',
    description: '',
  })
  const [value, setValue] = useState("0");
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateTheProfile = () => {
    setLoading(true);
    updateProfile(profile).then(r => {
      getProfileInfo()
      console.log('The profile is -> ', r)
    })
      .catch(error => {
        console.log('The error is -> ', error)
        setLoading(false);
      })
  }

  function getProfileInfo(firstLoad) {
    getProfile(slug).then(r => {
      setProfile(r)
      setLoading(false);
    })
      .catch(error => {
        console.log('The error is -> ', error)
        setLoading(false);
      }
      )
  }


  useEffect(() => {
    console.log('Profile in header is ', profile)

  }, [profile])

  useEffect(() => {
    getProfileInfo(true)

  }, [])

  const avatar = profile?.avatar ? `${API_BASE_URL}${profile?.avatar}` : noAvatar
  const background = profile?.background ? `${API_BASE_URL}${profile?.background}` : coworking

  return (
    <Container>
      {/* Global toolbar */}
      {/* Profile header */}
      <ProfileHeader title={profile?.name} subtitle={profile?.single_address} avatar={avatar} background={background} showAction={false} onUpdate={getProfileInfo} setLoading={setLoading} />
      <TabContext value={value}>
        <SecondRowContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Personal Info" value="0" />
            <Tab label="Professional info" value="1" />
            {/* <Tab label="Privacy" value="2" /> */}
          </Tabs>
          <div>
            {/* Tab content sections */}
            <TabPanel value="0" index={0}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Name" value={profile?.first_name} onChange={(e) => { setProfile({ ...profile, first_name: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Surname" value={profile?.last_name} onChange={(e) => { setProfile({ ...profile, last_name: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="emai" disabled value={profile?.email} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Job Title" value={profile?.alias} onChange={(e) => { setProfile({ ...profile, alias: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Brief Description"
                      value={profile?.description}
                      onChange={(e) => { setProfile({ ...profile, description: e.target.value }) }}
                      multiline
                      rows={4} />
                  </FormControl>
                </Grid>
              </Grid>

            </TabPanel>

            <TabPanel value="1" index={1}>
              {/* Content for Rules section */}
              <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Contact Link" value={profile?.link_1} onChange={(e) => setProfile({ ...profile, link_1: e.target.value })} />
                    <small>Add here your preferred contact link (LinkedIn, Calendy, Email address...)</small>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Project Link" value={profile?.link_2} onChange={(e) => setProfile({ ...profile, link_2: e.target.value })} />
                    <small>Add here a link with your main project or anything that can help others undertand you better (LinkedIn, Instagram, Personal website... )</small>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Other Link" value={profile?.link_3} onChange={(e) => setProfile({ ...profile, link_3: e.target.value })} />
                    <small>Add here any other relevant link you'd like to share</small>
                  </FormControl>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" index={2}>
              {/* Content for Rules section */}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom component="div">
                      Personal Info
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" gutterBottom component="div">
                      If the event is public, what data do you want to show? This is what people with the event or community link will see, no mater if they are registered or not
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} label="Share name" />
                      <FormControlLabel control={<Switch />} defaultChecked label="Share surname" />
                      <FormControlLabel control={<Switch />} label="Share email" />
                      <FormControlLabel control={<Switch />} label="Share phone" />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" gutterBottom component="div">
                      If the event is private, what data do you want to show? This is what people from the same community can see.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} disabled label="Share name" />
                      <FormControlLabel control={<Switch />} defaultChecked label="Share surname" />
                      <FormControlLabel control={<Switch />} label="Share email" />
                      <FormControlLabel control={<Switch />} label="Share phone" />
                    </FormGroup>
                  </Grid>
                  <Typography variant="h6" gutterBottom component="div">
                    Professional Info
                  </Typography>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} label="Allow Partners" />
                      <FormControlLabel control={<Switch />} defaultChecked label="Allow Activities" />
                      <FormControlLabel control={<Switch />} label="Allow Fees" />
                      <FormControlLabel control={<Switch />} label="Enable gallery" />
                      <FormControlLabel control={<Switch />} label="Allow user generated content" />
                    </FormGroup>
                  </Grid>

                </Grid>
              </div>


            </TabPanel>
            <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="secondary" aria-label="add" onClick={() => updateTheProfile()}>
                Save
              </Button>
            </Grid>
          </div>
        </SecondRowContainer>
      </TabContext>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}

Profile.propTypes = {}

export default Profile
