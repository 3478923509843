import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, ImageContainer, StyledEditorContainer, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventHeader from '../eventHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import EventListItem from '../eventListItem/view'
import { getEvent, getEvents, uploadEventImage, uploadEventBackground, getCommunities, getVenuesGroup, getEventTags, setEvent as saveEvent, updateEvent } from 'api'
import { TextField, FormControlLabel, Switch, FormGroup, Select, Grid, MenuItem, Avatar, Button } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import { API_BASE_URL } from '../../../../config/api'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import { set } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';

const EventForm = ({ event }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [events, setEvents] = useState([])

  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [instructions, setInstructions] = useState("")
  const [rules, setRules] = useState("")
  const [community, setCommunity] = useState('');
  const [communities, setCommunities] = useState([]);
  const [venues, setVenues] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeStart, setSelectedTimeStart] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);

  const [instructionsEditorState, setInstructionsEditorState] = useState(() =>
    instructions ? EditorState.createWithContent(convertFromHTML(instructions)) :
      EditorState.createEmpty()
  );

  const [rulesEditorState, setRulesEditorState] = useState( () =>
    rules ? EditorState.createWithContent(convertFromHTML(rules)) :
      EditorState.createEmpty()
  );



  const [theEvent, setEvent] = useState({
    slug: '',
    name: '',
    description: '',
    community: '',
    space: '',
    tags: [],
    date: null,
    start: null,
    end: null,
    tickets: 0,
    image: '',
    background: '',
    instructions: '',
    type: '',
    rules: ''
  })
  const [image, setImage] = useState(theEvent?.image ? `${API_BASE_URL}${theEvent?.image}` : noAvatar);
  const [background, setBackground] = useState(theEvent?.background ? `${API_BASE_URL}${theEvent?.background}` : noAvatar);
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function addEvent() {
    console.log(event)
    if (event?.slug) {
      updateEvent(theEvent).then(r => console.log(r))
        .catch(error => console.log('The error is -> ', error))
    } else {
      saveEvent(theEvent).then(r => console.log(r))
        .catch(error => console.log('The error is -> ', error))
    }

  }

  function getEventInfo(firstLoad) {
    getEvents(slug).then(r => {
      setEvents(r.events)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function navigateEvent(event) {
    navigate(`/events/${theEvent?.slug}`);
    //window.location.href = '/login'
  }

  function headerActionMethod(firstLoad) {
  }

  const handleCommunity = (event) => {
    setEvent({ ...theEvent, community: event.target.value })
  };

  const handleSpace = (event) => {
    setEvent({ ...theEvent, space: event.target.value })
  };

  const handleTags = (event) => {
    console.log(event.target.value)
    setEvent({ ...theEvent, tags: event.target.value })
  };


  const handleDateChange = (date) => {
    setEvent({ ...theEvent, date: date })
    setSelectedDate(date);
  };

  const handleTimeStartChange = (time) => {
    setEvent({ ...theEvent, start: time })
  };

  const handleTimeEndChange = (time) => {
    setEvent({ ...theEvent, end: time })
  };

  const handleUpload = () => {
    uploadEventImage(theEvent.slug, selectedFile, 'image').then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleUploadBackground = () => {
    uploadEventImage(theEvent.slug, selectedBackground, 'background').then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setSelectedFile({
          file: file,
          imageUrl: event.target.result,
        });
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
    setSelectedFile(file);
  };

  const handleBackgroundChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setSelectedBackground({
          file: file,
          imageUrl: event.target.result,
        });
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBackground(reader.result);
      };
    }
    setSelectedBackground(file);
  };

  const openFileInput = () => {
    document.getElementById('imageInput').click();
  };


  // Handle change for instructions editor
  const handleInstructionsEditorChange = (newEditorState) => {
    setInstructionsEditorState(newEditorState);
    setInstructions(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  // Handle change for rules editor
  const handleRulesEditorChange = (newEditorState) => {
    setRulesEditorState(newEditorState);
    setRules(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  useEffect(() => {
    if (selectedFile !== null) {
      handleUpload()
    }
  }, [selectedFile])

  useEffect(() => {
    if (selectedBackground !== null) {
      handleUploadBackground()
    }
  }, [selectedBackground])

  useEffect(() => {
    setEvent({ ...theEvent, description })
  }, [description])

  useEffect(() => {
    const htmlInstructions = convertToHTML(instructionsEditorState.getCurrentContent())
    console.log(htmlInstructions)
    setEvent({ ...theEvent, instructions: htmlInstructions })
  }, [instructions])

  useEffect(() => {
    const htmlRules = convertToHTML(rulesEditorState.getCurrentContent())
    console.log(htmlRules)
    setEvent({ ...theEvent, rules: htmlRules })
  }, [rules])

  useEffect(() => {
    getCommunities().then(r => {
      setCommunities(r.communities)
    }).catch(error => console.log('The error is -> ', error))
  }, [])


  useEffect(() => {
    if (event !== null) {
      console.log("loading event ", event)
      console.log(tags?.map((tag) => tag.name))
      const selectedTags = tags?.map((tag) => tag.name)
      console.log(event?.group)
      setEvent({
        slug: event?.slug,
        name: event?.name,
        description: event?.description,
        community: event?.group?.slug,
        space: event?.venue?.slug,
        tags: selectedTags,
        date: new Date(event?.start),
        start: new Date(event?.start),
        end: new Date(event?.end),
        tickets: event?.space_limit,
        image: event?.image,
        background: event?.background,
        instructions: event?.instructions,
        type: event?.type,
        rules: event?.rules
      })
      setDescription(event?.description)
      setInstructions(event?.instructions)
      setRules(event?.rules)
      setSelectedDate(new Date())
      // take the date from the date parsed from event.start
      setSelectedDate(dayjs(event?.start))
      setSelectedTimeStart(dayjs(event?.start))
      setSelectedTimeEnd(dayjs(event?.end))


      if (event?.instructions) {
        const blocksFromHTML = convertFromHTML(event?.instructions);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorState = EditorState.createWithContent(state);
        setInstructionsEditorState(editorState)
      }

      if (event?.rules) {
        const blocksFromHTML = convertFromHTML(event?.rules);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorState = EditorState.createWithContent(state);
        setRulesEditorState(editorState)
      }
    }

  }, [event])


  useEffect(() => {
    if (theEvent.community !== '') {
      getVenuesGroup(theEvent.community).then(r => {
        setVenues(r.venues)
      }).catch(error => console.log('The error is -> ', error))
      getEventTags(theEvent.community).then(r => {
        setTags(r.tags)
      }).catch(error => console.log('The error is -> ', error))
    }
  }, [theEvent.community])

  // Populate the Instructiosn Editor the first time

  const avatar = noAvatar
  return (
    <Container>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField label="Name" value={theEvent?.name} onChange={(e) => setEvent({ ...theEvent, name: e.target.value })} style={{ width: '60%' }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              value={theEvent?.community}
              onChange={handleCommunity}
              displayEmpty
              inputProps={{ 'aria-label': 'Community' }}
            >
              <MenuItem value="" disabled>Select a community</MenuItem>
              {communities?.map((item, index) => (
                <MenuItem value={item.slug}>{item.name}</MenuItem>
              ))
              }
              {/* Add more communities as needed */}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              value={theEvent?.space}
              onChange={handleSpace}
              displayEmpty
              inputProps={{ 'aria-label': 'Community' }}
            >
              <MenuItem value="" disabled>Select a venue</MenuItem>
              {venues?.map((item, index) => (
                <MenuItem value={item.slug}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              multiple
              value={theEvent?.tags}
              onChange={handleTags}
              displayEmpty
              inputProps={{ 'aria-label': 'Community' }}
            >
              <MenuItem value="" disabled>Select a tag</MenuItem>
              {tags?.map((item, index) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
              {/* Add more communities as needed */}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <StyledEditorContainer>
              <Editor
                editorState={instructionsEditorState}
                onEditorStateChange={handleInstructionsEditorChange}
              />
            </StyledEditorContainer>
          </Grid>
          <Grid item xs={12} sm={12}>
            <StyledEditorContainer>
              <Editor
                editorState={rulesEditorState}
                onEditorStateChange={handleRulesEditorChange}
              />
            </StyledEditorContainer>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Event date" value={selectedDate} onChange={handleDateChange} />
              <TimePicker label="Event start" value={selectedTimeStart} onChange={handleTimeStartChange} />
              <TimePicker label="Event end" value={selectedTimeEnd} onChange={handleTimeEndChange} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Available Tickets"
              type="number"
              value={theEvent.tickets}
              onChange={(e) => setEvent({ ...theEvent, tickets: e.target.value })}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: 1,
                },
              }}
            />

          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="imageInput"
                type="file"
                onChange={handleFileChange}
              />
              <ImageContainer>
                <Avatar
                  src={avatar}
                  alt="Avatar"
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '50%' }}
                  onClick={openFileInput}
                />
              </ImageContainer>
              <Typography variant="caption" display="block" gutterBottom>
                Upload event image
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="backgroundInput"
                type="file"
                onChange={handleBackgroundChange}
              />
              <ImageContainer>
                <Avatar
                  src={avatar}
                  alt="Avatar"
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '50%' }}
                  onClick={openFileInput}
                />
              </ImageContainer>
              <Typography variant="caption" display="block" gutterBottom>
                Upload event background
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="extended" color="primary" aria-label="add" style={{ width: '60%' }} onClick={() => addEvent()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

EventForm.propTypes = {
  event: null
}

export default EventForm
