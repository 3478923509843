import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import MemberListItem from '../memberListItem/view'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'

const MemberList = ({ members, setSelectedParticipant }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()

  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {members?.length === 0 && (
          <NoResultsPlaceholder firstSentence={t('No members found')} lastSentence={t('Try something different or wait a few minutes')} />
        )}
        {members?.map((item, index) => (
          <Grid item xs={2} sm={2} md={2} lg={1} key={index}>
            <MemberListItem member={item} setSelectedParticipant={setSelectedParticipant} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

MemberList.propTypes = {
  members: PropTypes.array,
  setSelectedParticipant: PropTypes.func
}

export default MemberList
