import React, { useEffect, useState } from 'react';
import LoginForm from 'components/organisms/loginForm';
import SignupForm from 'components/organisms/signupForm';
import bgLogin from 'assets/images/coworking.jpg';
import { useTranslation } from 'react-i18next';
import { Container, Header, Subtitle, Title, Wrapper, ModalContent } from './styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Typography, Button, Grid } from '@mui/material';
import { validateAccount } from 'api';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState();
  const [modalOpen, setModalOpen] = useState(false); 
  const [defaultEmail, setDefaultEmail] = useState('');
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    button: '',
    action: ''
  });
  const [isLoginPage, setIsLoginPage] = useState(location.pathname !== '/signup');

  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  useEffect(() => {
    if (id) {
      validateAccount({ id })
        .then((r) => {
          setIsLoginPage(true);
          setDefaultEmail(r.email);
          setModalContent({
            title: 'Account Verified',
            message: 'Your account has been verified. You can now login.',
            button: 'OK',
            action: 'navigate'
          });
        })
        .catch(() => {
          setModalContent({
            title: 'Error',
            message: 'We could not validate your account. Please try again later or get in touch with us.',
            button: 'OK',
            action: 'navigate'
          });
        })
        .finally(() => setModalOpen(true));
    }
  }, [id]);

  useEffect(() => {
    setIsLoginPage(location.pathname !== '/signup');
  }, [location.pathname]);

  const handleCloseModal = () => {
    setModalOpen(false);
    if (modalContent.action === 'navigate') {
      navigate('/login');
    }
  };

  const handleSignupSuccess = () => {
    setModalContent({
      title: 'Signup Successful!',
      message: 'We have sent you an email to verify your account. Please check your inbox and click the link to verify your account.',
      button: 'OK',
      action: 'navigate'
    });
    setModalOpen(true);
  };

  const title = isLoginPage ? 'login.title' : 'signup.title';
  const subtitle = isLoginPage ? 'login.subtitle' : 'signup.subtitle';

  return (
    <Container style={{ backgroundImage: `url(${loginInfo?.picture || bgLogin})` }}>
      <Wrapper elevation={0}>
        <Header>
          <Title variante='h1'>{t(title)}</Title>
          <Subtitle>{t(subtitle)}</Subtitle>
        </Header>
        {isLoginPage ? (
          <LoginForm defaultEmail={defaultEmail} />
        ) : (
          <SignupForm onSuccess={handleSignupSuccess} />
        )}
      </Wrapper>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <Typography variant="h6">{modalContent.title}</Typography>
          <Typography sx={{ mt: 2 }}>{modalContent.message}</Typography>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Button onClick={handleCloseModal} variant="contained" color="secondary">
              {modalContent.button}
            </Button>
          </Grid>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Login;
