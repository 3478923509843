import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { all } from 'axios';

const StyledGridItem = styled(Grid)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
}));

const IconTextWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});

const AboutUs = ({ community }) => {
    const getStatusLabel = (status) => {
        switch (status) {
            case 'AC':
                return 'Active';
            case 'IN':
                return 'Inactive';
            case 'PE':
                return 'Pending';
            default:
                return 'Unknown';
        }
    };

    const getStatusShortDescription = (status) => {
        switch (status) {
            case 'AC':
                return 'This community is currently active and accepting new members.';
            case 'IN':
                return 'This community is currently inactive and not accepting new members.';
            case 'PE':
                return 'This community is currently pending approval and not accepting new members.';
            default:
                return 'Unknown';
        }
    };

    const getVisibilityLabel = (visibility) => {
        switch (visibility) {
            case 'PU':
                return 'Public';
            case 'PR':
                return 'Private';
            default:
                return 'Unknown';
        }
    };

    const getPrivacyDescription = (privacy) => {
        switch (privacy) {
            case 'NO':
                return "This community does not share their members data internally or externally";
            case 'IN':
                return 'This community may share their members data internally, but never with other communities or parties'
            case 'PA':
                return 'This community may share their members data with other communities or parties'
            case 'CO':
                return 'This commuity may share their members data with other communities or parties, and also with their partners'
            default:
                return 'Unknown';
        }
    };

    const getVisibilityDescription = (visibility) => {
        switch (visibility) {
            case 'PU':
                return "This community is public, meaning that anyone can view and join it.";
            case 'PR':
                return 'This community is private, meaning that only invited members can join it'
            case 'SE':
                return 'This community is secret, how did you get here???'
            default:
                return 'Unknown';
        }
    };


    const getValorationStars = (valoration) => {
        const rating = parseFloat(valoration);
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (rating >= i) {
                stars.push(<StarIcon key={i} sx={{ color: 'gold' }} />);
            } else {
                stars.push(<StarBorderIcon key={i} sx={{ color: rating === 0 ? 'gray' : 'gold' }} />);
            }
        }
        return stars;
    };

    const getPrivacyIcons = (dataProtection) => {
        switch (dataProtection) {
            case 'NO':
                return (
                    <>
                        <LockIcon sx={{ color: 'gray' }} />
                        <LockIcon sx={{ color: 'gray' }} />
                        <LockIcon sx={{ color: 'gray' }} />
                    </>
                );
            case 'CM':
                return (
                    <>
                        <LockIcon sx={{ color: 'gray' }} />
                        <LockIcon sx={{ color: 'gray' }} />
                        <LockOpenIcon sx={{ color: 'blue' }} />
                    </>
                );
            case 'PA':
                return (
                    <>
                        <LockIcon sx={{ color: 'gray' }} />
                        <LockOpenIcon sx={{ color: 'green' }} />
                        <LockOpenIcon sx={{ color: 'green' }} />
                    </>
                );
            default:
                return (
                    <>
                        <LockIcon sx={{ color: 'red' }} />
                        <LockOpenIcon sx={{ color: 'red' }} />
                    </>
                );
        }
    };

    const getRoleDescription = (role) => {
        switch (role) {
            case 'PA':
                return "As soon as you sign up, you will be assigned the role of Participant. This role allows you to interact with other members but not modify community information.";
            case 'AD':
                return "Upon signing up, you will be assigned the role of Administrator. This role grants you full control over the community, including member management and content modification.";
            case 'MA':
                return "As a Moderator, your role includes overseeing community interactions, ensuring that discussions remain productive and on-topic.";
            case 'GU':
                return "Once you sign up, you will be assigned the role of Guest. This role allows you to view community content but not participate in discussions or events.";
            default:
                return "The role assigned to new members will dictate their level of access and privileges within the community.";
        }
    };

    const getStatusDescription = (status) => {
        console.log('Status ', status)
        switch (status) {
            case 'AC':
                return "New members are automatically activated upon signing up and can immediately start participating in the community.";
            case 'PP':
                return "New members will remain in a pending status until their account is verified or approved by an administrator.";
            case 'IN':
                return "New members will be inactive upon signing up and require further steps to activate their account.";
            default:
                return "The status assigned to new members determines their ability to engage with the community.";
        }
    };

    const getFeesDescription = (usesFees) => {
        return usesFees
            ? "This community charges fees for certain services or events, with a portion going towards community maintenance and development."
            : "This community does not charge any fees for participation, making all services and events freely accessible to its members.";
    };

    return (
        <div>
            {/* Header Grid with Icons on the Left */}
            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                <StyledGridItem item xs={12} sm={4}>
                    <IconTextWrapper>
                        <Typography variant="h6">{community?.name}</Typography>
                    </IconTextWrapper>
                </StyledGridItem>
                <StyledGridItem item xs={6} sm={2}>
                    <IconTextWrapper>
                        <Tooltip title="The current status of the community (Active/Inactive/Pending)">
                            <InfoIcon />
                        </Tooltip>
                        <Typography variant="subtitle1">{getStatusLabel(community?.status)}</Typography>
                    </IconTextWrapper>
                </StyledGridItem>
                <StyledGridItem item xs={6} sm={2}>
                    <IconTextWrapper>
                        <Tooltip title={getVisibilityDescription(community?.visibility)}>
                            <VisibilityIcon />
                        </Tooltip>
                        <Typography variant="subtitle1">{getVisibilityLabel(community?.visibility)}</Typography>

                    </IconTextWrapper>
                </StyledGridItem>
                <StyledGridItem item xs={6} sm={2}>
                    <IconTextWrapper>
                        <Tooltip title={getStatusShortDescription(community?.status)}>
                            <div>{getValorationStars(community?.valoration)}</div>
                        </Tooltip>
                    </IconTextWrapper>
                </StyledGridItem>
                <StyledGridItem item xs={6} sm={2}>
                    <IconTextWrapper>
                        <Tooltip title={getPrivacyDescription(community?.data_protection)}>
                            <div>{getPrivacyIcons(community?.data_protection)}</div>
                        </Tooltip>
                    </IconTextWrapper>
                </StyledGridItem>
            </Grid>

            {/* Main Content */}
            <Typography variant="body2">
                {community?.description}
            </Typography>

            <Typography variant="h6" component="h2" sx={{ marginTop: '30px' }}>
                About {community?.name}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: community?.promotional_text }} />

            {/* Two-Column Layout for Rules and Tags */}
            <Grid container spacing={2} sx={{ marginTop: '30px' }}>
                <Grid item xs={12} sm={6}>
                    {community?.rules && (
                        <div>
                            <Typography variant="h6" component="h2">
                                Community Rules
                            </Typography>
                            <div dangerouslySetInnerHTML={{ __html: community?.rules }} />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" component="h2">
                        Tags & Topics
                    </Typography>
                    <div>
                        {community?.event_tags?.map((tag, index) => (
                            <Chip key={index} label={tag.name} sx={{ marginRight: '5px', marginBottom: '5px' }} />
                        ))}
                    </div>
                </Grid>
            </Grid>

            {/* Footer with Additional Information */}
            <Typography variant="h6" component="h2" sx={{ marginTop: '30px' }}>
                Additional Information
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px' }}>
                <strong>Default Role:</strong> {getRoleDescription(community?.default_role)}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px' }}>
                <strong>Default Status:</strong> {getStatusDescription(community?.default_status)}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px' }}>
                <strong>Uses Fees:</strong> {getFeesDescription(community?.use_fees)}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px' }}>
                <strong>Contact:</strong> <a href={`mailto:${community?.contact_email}`}>{community?.contact_email}</a>
            </Typography>
        </div>
    );
};

export default AboutUs;
