import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {
  Container, SecondRowContainer, ModalContent, StyledFab
} from './styled'
import CommunityHeader from '../communityHeader/view'
import MemberList from '../memberList/view'
import MemberDetail from '../memberDetail/view'
import VenueList from '../venueList/view'
import EventList from '../eventList/view'
import CommunityForm from '../communityForm/view'
import { getCommunity, getPublicEventTicket, joinCommunity } from 'api'
import {
  Box, Fab, Modal, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid
} from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import SideMenu from '../sideMenu/view'
import { Gallery } from 'react-grid-gallery'
import Typography from '@mui/material/Typography'
import RefreshIcon from '@mui/icons-material/Refresh'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { API_BASE_URL } from 'config/api'
import coworking from 'assets/images/coworking.jpg'
import AboutUs from './aboutUs'
import Members from './members'
import Activity from './activity'
import Partners from './partners'
import Manage from './manage'
import Campaigns from './campaign'
import CommunityJoin from '../comunityJoin'



const CommunitySingle = props => {
  const { profile } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  // get the doJoin value from the query params
  const location = useLocation();


  const [community, setCommunity] = useState(null)
  const [member, setMember] = useState(null)
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [venues, setVenues] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState(0)
  const [name, setName] = useState("AI Caramaba")
  const [description, setDescription] = useState("Ai caramba is a community of people who love AI")
  const [communitySlug, setCommunitySlug] = useState("ai-caramba")
  const [showAction, setShowAction] = useState(false)
  const [roleExtended, setRoleExtended] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [communityJoin, setCommunityJoin] = useState(false)
  const [alreadyJoined, setAlreadyJoined] = useState(false)

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(prev => !prev)
  }, [])

  const handleChange = useCallback((newValue) => {
    setValue(newValue)
  }, [])

  const handleJoin = useCallback((result) => {
    console.log('Join community done in Community ', result)
    if (result) {
      getCommunityInfo(false)
      setCommunityJoin(false)
    }
  }, [])

  const getCommunityInfo = useCallback((firstLoad) => {
    getCommunity(slug).then(r => {
      setCommunity(r.group)
      setFounders(r.founders)
      setMembers(r.members)
      setVenues(r.venues)
      setPastEvents(r.past_events)
      setUpcomingEvents(r.upcoming_events)
      setGallery(r.gallery)
      const imMember = r.members.find(m => m.user?.user_name === profile?.user_name)
      if (imMember) {
        setAlreadyJoined(true)
        setRoleExtended(imMember.role === 'AD' || imMember.role === 'FO' || imMember.role === 'MA')
      } else {
        const doJoin = new URLSearchParams(location.search).get('doJoin');
        if (doJoin) {
          setCommunityJoin(true)
        }
        setRoleExtended(false)
      }
      
    }).catch(error => console.log('The error is -> ', error))
  }, [slug, profile?.user_name])

  const askToJoin = () => {
    setCommunityJoin(true)
  }

  useEffect(() => {
    getCommunityInfo(true)
  }, [getCommunityInfo])


  const avatar = community?.image ? `${API_BASE_URL}${community?.image}` : noAvatar
  const background = community?.background ? `${API_BASE_URL}${community?.background}` : coworking

  const images = [
    { src: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg", width: 240, height: 320, caption: "8H (gratisography.com)", thumbnailCaption: "8H" },
    { src: "https://c3.staticflickr.com/9/8583/28354353794_9f2d08d8c0_b.jpg", width: 320, height: 190, caption: "286H (gratisography.com)", thumbnailCaption: "286H" },
    { src: "https://c7.staticflickr.com/9/8569/28941134686_d57273d933_b.jpg", width: 320, height: 148, caption: "315H (gratisography.com)", thumbnailCaption: "315H" },
    { src: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg", width: 320, height: 213, caption: "201H (gratisography.com)", thumbnailCaption: "201H" },
    { src: "https://c2.staticflickr.com/9/8239/28897202241_1497bec71a_b.jpg", width: 248, height: 320, caption: "Big Ben (Tom Eversley - isorepublic.com)", thumbnailCaption: "Big Ben" },
    { src: "https://c7.staticflickr.com/9/8785/28687743710_3580fcb5f0_b.jpg", width: 320, height: 113, caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)", thumbnailCaption: <span style={{ color: "darkred" }}>Red Zone - <i>Paris</i></span> },
    { src: "https://c6.staticflickr.com/9/8520/28357073053_cafcb3da6f_b.jpg", width: 313, height: 320, caption: "Wood Glass (Tom Eversley - isorepublic.com)", thumbnailCaption: "Wood Glass" },
    { src: "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_b.jpg", width: 320, height: 213, caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)", thumbnailCaption: "Flower Interior Macro" },
  ]

  return (
    <Container>
      <CommunityHeader title={community?.name} subtitle={community?.single_address} avatar={avatar} background={background} actionTitle='Join Community' showAction={showAction} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <SideMenu open={drawerOpen} onClose={handleDrawerToggle} changeTab={handleChange} avatar={avatar} title={community?.name} subtitle={community?.single_address} role={roleExtended ? 'admin' : 'participant'}/>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            {value === 0 && <AboutUs community={community} />}
            {value === 1 && <Members members={members} member={member} setMember={setMember} setSelectedParticipant={setMember} tags={[]} />}
            {value === 2 && <Activity community={community} upcomingEvents={upcomingEvents} pastEvents={pastEvents} setCommunityJoin={askToJoin}/>}
            {value === 3 && <Partners venues={venues} slug={slug} />}
            {value === 4 && <Campaigns slug={slug} />}
            {value === 5 && <Manage community={community} />}
            <TabContext value={value}>
              <SecondRowContainer>
                <div>
                  <TabPanel value="7" index={7}>
                    <Gallery images={images} />
                  </TabPanel>
                </div>
              </SecondRowContainer>
            </TabContext>
          </Box>
        </Grid>
      </Grid>

      {!alreadyJoined && <Fab color="primary" variant="extended" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => setCommunityJoin(true)}>
        <Typography>Join Community</Typography>
      </Fab>}
      <Modal open={communityJoin} onClose={() => setCommunityJoin(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <ModalContent>
          <CommunityJoin setJoin={(result) => handleJoin(result)} postAction={{ action: 'joinCommunity', slug: community?.slug }} community={community}/>
        </ModalContent>
      </Modal>
    </Container>
  )
}

CommunitySingle.propTypes = {}

export default CommunitySingle
