import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  HeroContainer,
  LowerRowContainer,
  StyledAvatar,
  StyledTabs,
  CommunityName,
  FirstRowContainer,
  CommunityStatus,
  JoinButton,
  SecondRowContainer,
  InfoContainer,
  AvatarContainer,
  ContentContainer,
  CommunityBasic
} from './styled'

import { Avatar, Fab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { uploadProfileImage } from 'api'
import noAvatar from 'assets/images/no-avatar-upload.png'
import { API_BASE_URL } from 'config/api'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

const ProfileHeader = ({ title, subtitle, avatar, background, actionTitle, actionMethod, showAction = false, onUpdate, setLoading }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [theAvatar, setAvatar] = useState(avatar ? `${API_BASE_URL}${avatar}` : noAvatar);
  const [theBackground, setBackground] = useState(background ? `${API_BASE_URL}${background}` : null);



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setAvatar(e.target.result);
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleBackgroundChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setBackground(e.target.result);
      reader.readAsDataURL(file);
      setSelectedBackground(file);
    }
  };

  const handleUpload = async (type) => {
    try {
      console.log('Uploading file ->', type)
      setLoading(true);
      const file = type === 'avatar' ? selectedFile : selectedBackground;
      const response = await uploadProfileImage(type, file);
      if (response && response.status === 200) {
        console.log(response.data);
        if (type === 'avatar') {
          setSelectedFile(null);
        } else {
          setSelectedBackground(null);
        }
        onUpdate();
      } else {
        setLoading(false);
        console.error('Error:', response);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };



  const openFileInput = () => {
    console.log('Opening file input')
    document.getElementById('avatarInput').click();
  };

  const openBackgroundInput = () => {
    console.log('Opening background input')
    document.getElementById('backgroundInput').click();
  };

  useEffect(() => {
    console.log('Selected file ->', selectedFile)
    if (selectedFile !== null) {
      handleUpload('avatar')
    }
  }, [selectedFile])

  useEffect(() => {
    console.log('Selected background ->', selectedBackground)
    if (selectedBackground !== null) {
      handleUpload('background')
    }
  }, [selectedBackground])

  return (
    <Container>
      <HeroContainer elevation={0} background={background}>
        
        <Fab
          color="primary"
          aria-label="add"
          onClick={openBackgroundInput}
          style={{
            position: 'absolute',
            bottom: 16,
            right: 16,
          }}
        >
          <AddPhotoAlternateIcon />
        </Fab>
        <StyledAvatar alt="Avatar" src={avatar} onClick={openFileInput} />
        {/* Content for the Hero section */}
      </HeroContainer>

      <InfoContainer>
        <AvatarContainer>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="avatarInput"
            type="file"
            onChange={handleFileChange}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="backgroundInput"
            type="file"
            onChange={handleBackgroundChange}
          />
        </AvatarContainer>
        <ContentContainer>

          <LowerRowContainer>
            <FirstRowContainer>
              <CommunityBasic>
                <CommunityName>{title}</CommunityName>
                <CommunityStatus>{subtitle}</CommunityStatus>
              </CommunityBasic>

              {showAction && <JoinButton variant="contained" color="primary" onClick={() => actionMethod()}>
                {actionTitle}
              </JoinButton>}
            </FirstRowContainer>


          </LowerRowContainer>
        </ContentContainer>
      </InfoContainer>
    </Container>

  )
}

ProfileHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.string,
  background: PropTypes.string,
  actionTitle: PropTypes.string,
  actionMethod: PropTypes.func,
  showAction: PropTypes.bool
}

export default ProfileHeader
