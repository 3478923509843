import React, { useEffect, useState } from 'react'
import bgReset from 'assets/images/reset-success.jpg'
import { useTranslation } from 'react-i18next'
import { Container, Header, Subtitle, Title, Wrapper } from './styled'
import { confirmPassword } from 'api'
import { TextField, Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import PassInput from 'components/atoms/passInput'

const ResetConfirm = () => {
  const [resetStatus, setResetStatus] = useState(0)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { slug } = params
  const [formParams, setFormParams] = useState({
    password: '',
    slug
  })
  const [open, setOpen] = useState(false)


  function verifyReset() {
    const postData = formParams;
    confirmPassword(postData).then(r => {
      console.log('Password reset success', r)
      setOpen(true)
      setResetStatus(1)
    }).catch(error => {
      console.log('Password reset error -> ', error)
      setOpen(true)
      setResetStatus(-1)
    })
  }

  const handleClose = () => {
    setOpen(false);
    navigate('/login')
  };


  return (
    <Container style={{ backgroundImage: `url(${bgReset})` }}>
      <Wrapper elevation={0}>
        <Header>
          <Title variant='h4'>{t('reset.title')}</Title>
          <Subtitle>{t('reset.confirm')}</Subtitle>
        </Header>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label={t('login.passwordLabel')}
              type='password'
              name='password'
              value={formParams.password}
              onChange={(e) => setFormParams({ ...formParams, password: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{marginTop: '20px'}}>
            <Button variant='contained' color='secondary' type='submit' fullWidth onClick={() => verifyReset()}>
              {t('reset.update')}
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Password reset"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your password has been reset successfully. Please return to login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default ResetConfirm
