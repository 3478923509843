import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import VenueHeader from '../venueHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import VenueListItem from '../venueListItem/view'
import { getCommunity, getVenues } from 'api'
import { Grid, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import noAvatar from '../../../../assets/images/no-avatar.png'


const VenueHome = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [venues, setVenues] = useState([])
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");

  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getVenuesInfo(firstLoad) {
    getVenues(slug).then(r => {
      setVenues(r.venues)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function navigateVenue(venue) {    
    navigate(`/venue/${venue?.slug}`);      
    //window.location.href = '/login'
  }

  function headerActionMethod(firstLoad) {
    //console.log('Header action')
  }

  useEffect(() => {
    getVenuesInfo(true)

  }, [])


  const avatar = noAvatar
  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <InternalHeader title={'Your spaces'} subtitle={''} avatar={avatar} background={coworking} actionTitle='Find spaces' actionMethod={headerActionMethod} />
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {venues?.map((item, index) => (
          <Grid item xs={2} sm={2} md={2} lg={1} key={index}>
            <VenueListItem venue={item} selectVenue={(item) => navigateVenue(item)} />
          </Grid>
        ))}
      </Grid>
      {/* List of communities */}
    </Container>
  )
}

VenueHome.propTypes = {}

export default VenueHome
