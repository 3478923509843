import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom'

import View from './view'



const SignupForm = ({ ...props }) => {
  const onSuccess = props.onSuccess
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { doSignup } = useAuth();

  

  const mutateSignup = useMutation(data => doSignup(data), {
    onSuccess: (result, variables) => {
      if (onSuccess) {
        console.log('Mutate Signup Success', result)
        console.log('Mutate Signup Success Variables', variables)
        // Perform login with the same credentials
        //mutateLogin.mutate({ email, password })
        
        onSuccess(result)
      }
      //navigate('/communities')
    },
    onError: () => {
      enqueueSnackbar(t('signup.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    console.log('Mutate Signup', data)
    mutateSignup.mutate(data)
  }

  return <View {...props} onSubmit={onSubmit} onSuccess={onSuccess}/>
}

export default SignupForm
