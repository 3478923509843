import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { getRecommendedCommunities } from 'api';
import noAvatar from 'assets/images/no-avatar.png';
import { API_BASE_URL } from 'config/api';

function CommunitySlider({ navigateCommunity }) {
  const [communities, setCommunities] = useState([]);
  const isDragging = useRef(false);

  function getCommunityList() {
    getRecommendedCommunities()
      .then((r) => {
        console.log('Communities', r);
        setCommunities(r.communities);
      })
      .catch((error) => console.log('The error is -> ', error));
  }

  const handleBeforeChange = () => {
    isDragging.current = true;
  };

  const handleAfterChange = () => {
    isDragging.current = false;
  };

  const handleCardClick = (slug) => {
    if (!isDragging.current) {
      navigateCommunity(slug);
    }
  };

  useEffect(() => {
    getCommunityList();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5', marginTop: '40px' }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{
          textAlign: 'center', // Change to 'left' for left alignment
          marginBottom: '20px',
          fontWeight: 'bold',
        }}
      >
        Browse recommended communities
      </Typography>
      <Slider {...settings}>
        {communities.map((community, index) => {
          const image = community?.image
            ? `${API_BASE_URL}${community.image}`
            : noAvatar;

          return (
            <Box key={index} sx={{ paddingX: '15px', paddingY: '10px' }}>  {/* Increased horizontal separation */}
              <Card
                sx={{
                  maxWidth: 345,
                  mx: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250, // Reduced height for the card
                  margin: '5px', // Adjust padding within the card
                  cursor: 'pointer',
                }}
                onClick={() => handleCardClick(community.slug)}
              >
                <CardMedia
                  component="img"
                  height="120" // Slightly reduced the image height
                  image={image}
                  alt={community.name}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '10px', // Adjust padding within the content
                  }}
                >
                  <Typography gutterBottom variant="h6" component="div">
                    {community.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                      mt: 1, // Add some margin at the top
                    }}
                  >
                    {community.description}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

export default CommunitySlider;
