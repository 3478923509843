import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalDialog from 'components/molecules/modalDialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PassInput from 'components/atoms/passInput'
import TextInput from 'components/atoms/textInput'
import { Container, ForgotButton } from './styled'
import validate from './validate'

const ResetFormView = ({ onSubmit, setShowReset }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })

  const [open, setOpen] = useState(false)

  const handleClickForgot = () => {
    navigate('/login')
  }

  const handleClose = () => {
    setOpen(false);
    navigate('/login')
  };

    // Modify the onSubmit function to trigger the dialog
    const handleFormSubmit = async (data) => {
      try {
        console.log(data)
        await onSubmit(data)
        // If the submission is successful, show the dialog
        console.log('Password reset successful')
        setOpen(true)
      } catch (error) {
        console.error('Password reset failed', error)
      }
    }

  return (
    <>
      <Container onSubmit={handleSubmit(handleFormSubmit)}>
        <TextInput label={t('login.emailLabel')} name='email' control={control} error={errors?.email} />
        <ForgotButton onClick={handleClickForgot}>{t('reset.backLogin')}</ForgotButton>
        <Button variant='contained' color='secondary' type='submit'>
          {t('reset.reset')}
        </Button>
      </Container>      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Password reset"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We have sent an email to your account to reset your password. Please check your inbox and return to login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ResetFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ResetFormView
