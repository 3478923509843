import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, Typography } from '@mui/material'
import { Container, Description, Prompt, ButtonContainer } from './styled'
import { joinCommunity } from 'api'

const CommunityJoin = ({ setJoin, postAction, community }) => {

  const handleJoin = () => {
    const data = {
      community: postAction?.slug,
    }
    joinCommunity(data)
      .then(() => {
        setJoin(true)
      })
      .catch((error) => {
        console.log('Join community error', error)
        setJoin(false)
      })
  }

  const handleCancel = () => {
    setJoin(false)
  }

  return (
    <Container>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {community.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Description variant="body1" align="center">
            {community.description}
          </Description>
        </Grid>
        <Grid item xs={12}>
          <Prompt variant="body1" align="center">
            Are you sure you want to join the {community.name} community? Please press the button to confirm.
          </Prompt>
        </Grid>
        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleJoin}
              fullWidth
            >
              Join Community
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </ButtonContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

CommunityJoin.propTypes = {
  setJoin: PropTypes.func,
  postAction: PropTypes.object.isRequired,
  community: PropTypes.object.isRequired,
}

CommunityJoin.defaultProps = {
  setJoin: () => null,
  postAction: {},
  community: {},
}

export default CommunityJoin
