import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  HeroContainer,
  LowerRowContainer,
  StyledAvatar,
  StyledTabs,
  InternalName,
  FirstRowContainer,
  InternalStatus,
  JoinButton,
  SecondRowContainer,
  InfoContainer,
  AvatarContainer,
  ContentContainer,
  InternalBasic
} from './styled'

import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { getEventTicket, getPublicEventTicket } from 'api'
import { useNavigate } from 'react-router'

import coworking from 'assets/images/coworking.jpg'

import { useProfile } from 'context'

const InternalHeader = ( { title, subtitle, avatar, background, actionTitle, actionMethod } ) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [theParticipants, setParticipants] = useState([])
  const [myTicket, setMyTicket] = useState(null)
  const [theEvent, setEvent] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [joinModal, setJoinModal] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [ticketInfo, setTicketInfo] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))

  const { profile } = useProfile();

  return (
    <Container>
      <HeroContainer elevation={0} background={background}>
        <StyledAvatar alt="Avatar" src={avatar} />
        {/* Content for the Hero section */}
      </HeroContainer>

      <InfoContainer>
        <AvatarContainer></AvatarContainer>
        <ContentContainer>

          <LowerRowContainer>
            <FirstRowContainer>
              <InternalBasic>
                <InternalName>{title}</InternalName>
                <InternalStatus>{subtitle}</InternalStatus>
              </InternalBasic>

              {profile.staff && <JoinButton variant="contained" color="primary" onClick={() => actionMethod()}>
                {actionTitle}
              </JoinButton>}
            </FirstRowContainer>


          </LowerRowContainer>
        </ContentContainer>
      </InfoContainer>
    </Container>

  )
}

InternalHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.string,
  background: PropTypes.string,
  actionTitle: PropTypes.string,
  actionMethod: PropTypes.func
}

export default InternalHeader
