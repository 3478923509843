import { validateAccount } from "api"

const routes = {
  home: '/',
  login: '/login',
  signup: '/signup',
  reset: '/reset',
  resetConfirm: '/reset-password/:slug',
  validateAccount: '/validate-account',
  aboutUs: '/about-us',
  dashboard: '/dashboard',
  venues: '/venues',
  venue: '/venue/:slug',
  venueManage: '/manage/venue/:slug',
  venueBookingDetail: '/venue/booking/:slug',
  venueBookingDetailPublic: '/public/venue/booking/:slug',
  communities: '/communities',
  community: '/community/:slug',
  communityPublic: '/public/community/:slug',
  communityVenue: '/community/:slug/venue/:venueSlug',
  communityBooking: '/community/:slug/booking/:bookingSlug',
  events: '/events',
  eventAdmin: '/event-manage',
  eventUpdate: '/event-update/:slug',
  eventParticipant: '/events/:slug',
  eventTicket: '/event-ticket/:slug',
  bookings: '/bookings',
  booking: '/bookings/:slug',
  bookingResult: '/booking-result/:sessionId',
  bookingAdmin: '/booking-manage',
  bookingFind: '/booking-find',
  spaces: '/space',
  profile: '/profile',
  privacy: '/privacy-policy',
  signUp: '/signup',
  checkMail: '/checkmail',
  onBoarding: '/on_boarding',
  error404: '/error/404',
}

export default routes
