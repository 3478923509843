import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import Login from 'components/pages/login'
import Reset from 'components/pages/reset'
import ResetConfirm from 'components/pages/resetConfirm'
import Home from 'components/pages/public/home'
import AboutUs from 'components/pages/public/aboutUs'
import Privacy from 'components/pages/public/privacy'
import Dashboard from 'components/pages/dashboard'
import EventHome from 'components/pages/event/eventHome'
import EventParticipant from 'components/pages/event/eventParticipant'
import EventTicket from 'components/pages/event/eventTicket'
import EventAdmin from 'components/pages/event/eventAdmin'
import EventUpdate from 'components/pages/event/eventUpdate'
import VenueHome from 'components/pages/venue/venueHome'
import VenueSingle from 'components/pages/venue/venueSingle'
import VenueManage from 'components/pages/venue/venueManage'
import VenueBookingDetail from 'components/pages/venue/bookingDetail/container'
import VenueBookingDetailPublic from 'components/pages/venue/bookingDetailPublic/container'
import BookingHome from 'components/pages/booking/bookingHome'
import BookingSingle from 'components/pages/booking/bookingSingle'
import BookingResult from 'components/pages/booking/bookingResult/view'
import BookingAdmin from 'components/pages/booking/bookingAdmin/view'
import BookingFind from 'components/pages/booking/bookingFind/view'
import Profile from 'components/pages/profile/profile/view'
import SignUp from 'components/pages/signUp/signUp'
import CheckMail from 'components/pages/checkMail/checkMail'
import Error404 from 'components/pages/errors/error404'
import RouteWrapper from './RouterWrapper'
import CommunitySingle from 'components/pages/community/communitySingle'
import CommunitySinglePublic from 'components/pages/community/communitySinglePublic'
import CommunityHome from 'components/pages/community/communityHome'
import CommunityVenueHome from 'components/pages/community/venueSingle'
import CommunityBookingHome from 'components/pages/community/bookingSingle'
import RequireAuth from './RequireAuth'
import Layout from 'components/template/layout'

const AppRoutes = props => {
  const { profile } = props
  const location = useLocation()
  const theUrl = location.pathname?.split('/')
  const loggedIn = localStorage.getItem('token')

  return (
    <Routes>
      {loggedIn && (
        <Route
          path={routes.home}
          element={
            <RequireAuth layoutType='logged' breadcrumb>
              <Layout>
                <CommunityHome />
              </Layout>
            </RequireAuth>
          }
        />
      )}
      {!loggedIn && (
        <>
          <Route path={routes.home} element={<Home />} />
        </>
      )}
      <Route path={routes.aboutUs} element={<AboutUs />} />
      <Route path={routes.login} element={
        <Layout>
          <Login />
        </Layout>
      } />
      <Route path={routes.signup} element={
        <Layout>
          <Login />
        </Layout>
      } />

      <Route path={routes.validateAccount} element={
        <Layout>
          <Login />
        </Layout>
      } />
      <Route path={routes.reset} element={
        <Layout>
          <Reset />
        </Layout>
      } />
      <Route path={routes.resetConfirm} element={
        <Layout>
          <ResetConfirm />
        </Layout>
      } />
      <Route path={routes.privacy} element={<Privacy />} />

      <Route path={routes.bookingFind} element={
        <Layout>
          <BookingFind />
        </Layout>
      } />

      <Route
        path={routes.eventParticipant}
        element={
          <Layout>
            <EventParticipant />
          </Layout>
        }
      />
      <Route
        path={routes.eventTicket}
        element={
          <Layout>
            <EventTicket />
          </Layout>
        }
      />

      <Route
        path={routes.community}
        element={
          <RequireAuth layoutType='logged' isA breadcrumb>
            <Layout>
              <CommunitySingle profile={profile} />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.communityPublic}
        element={
          <Layout>
            <CommunitySinglePublic />
          </Layout>
        }
      />
      <Route
        path={routes.communities}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <CommunityHome />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.events}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <EventHome />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.eventUpdate}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <EventUpdate />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.venues}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <VenueHome />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.venue}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <VenueSingle />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.venueManage}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <VenueManage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.venueBookingDetail}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <VenueBookingDetail />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.venueBookingDetailPublic}
        element={
          <Layout>
            <VenueBookingDetailPublic />
          </Layout>
        }
      />
      <Route
        path={routes.communityVenue}
        element={
          <Layout>
            <CommunityVenueHome />
          </Layout>
        }
      />
      <Route
        path={routes.communityBooking}
        element={
          <Layout>
            <CommunityBookingHome />
          </Layout>
        }
      />
      <Route
        path={routes.eventTicket}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <EventTicket />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.eventAdmin}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <EventAdmin />
            </Layout>
          </RequireAuth>
        }
      />
      <Route path={routes.bookingResult} element={
        <RequireAuth layoutType='logged' breadcrumb>
          <Layout>
            <BookingResult />
          </Layout>
        </RequireAuth>} />
      <Route
        path={routes.bookings}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <BookingHome />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.bookingAdmin}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <BookingAdmin />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.booking}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <BookingSingle />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={routes.profile}
        element={
          <RequireAuth layoutType='logged' breadcrumb>
            <Layout>
              <Profile />
            </Layout>
          </RequireAuth>
        }
      />
      <Route path='*' element={<Navigate to={routes.error404} />} />
    </Routes>
  );
}


AppRoutes.propTypes = {
  profile: PropTypes.object,
}

AppRoutes.defaultProps = {
  profile: null,
}

export default AppRoutes
