import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import VenueHeader from '../venueHeader/view'
import SideMenu from '../sideMenu/view';

import EventList from '../../community/eventList/view'
import BookingList from '../bookingList/view'
import BookingPlanList from '../bookingPlanList/view'
import { getVenue, getCommunitiesForMember } from 'api'
import { Grid, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Box } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import noAvatar from '../../../../assets/images/no-avatar.png'

import dayjs, { Dayjs } from 'dayjs';


import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';


const VenueSingle = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [venue, setVenue] = useState(null)
  const [workSpace, setWorkSpace] = useState([])
  const [amenities, setAmenities] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [communities, setCommunities] = useState([])
  const [gallery, setGallery] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(true);
  

  const [value, setValue] = useState(0);
  
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleChange = (newValue) => {
    console.log(value)
    setValue(newValue);
  };



  function getCommunityInfo(firstLoad) {
    getCommunitiesForMember().then(r => {
      console.log('Communities', r)
      setCommunities(r.communities)
    })
      .catch(error => console.log('The error is -> ', error))
  }


  function getVenueInfo(firstLoad) {
    getVenue(slug).then(r => {
      setVenue(r)
    })
      .catch(error => console.log('The error is -> ', error))
  }



  useEffect(() => {
    getVenueInfo(true)
    getCommunityInfo(true)
  }, [])





  const avatar = venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar
  const background = venue?.background ? `${API_BASE_URL}${venue?.background}` : noAvatar
  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <VenueHeader title={venue?.name} subtitle={venue?.single_address} avatar={avatar} background={background} />
      <Grid container spacing={2}>
        {/* Left Menu */}
        <Grid item xs={12} md={2}>
          <SideMenu open={drawerOpen} onClose={handleDrawerToggle} changeTab={handleChange} avatar={avatar} title={venue?.name} subtitle={venue?.single_address} />
        </Grid>
        <Grid item xs={12} md={10}>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            {value === 0 && <BookingList slug={slug} communities={communities}/>}
            {value === 1 && <BookingPlanList slug={slug} communities={communities}/>}
          </Box>
        </Grid>
      </Grid>

    </Container>
  )
}

VenueSingle.propTypes = {}

export default VenueSingle
