import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, ImageContainer, StyledEditorContainer, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { Form, useLocation, useParams } from 'react-router-dom'
import InternalHeader from '../../general/internalHeader/view'
import { getCommunity, uploadCommunityImage, getVenuesGroup, getEventTags, setCommunity as saveCommunity, updateCommunity } from 'api'
import { TextField, FormControlLabel, Switch, FormGroup, Select, Grid, MenuItem, Avatar, Button, FormControl, InputLabel, Divider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import { API_BASE_URL } from '../../../../config/api'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import dayjs, { Dayjs } from 'dayjs';

const CommunityForm = ({ community }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [communitys, setCommunitys] = useState([])

  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [promotionalText, setPromotionalText] = useState("")
  const [rules, setRules] = useState("")
  const [venues, setVenues] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeStart, setSelectedTimeStart] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);

  const [promotionalTextEditorState, setPromotionalTextEditorState] = useState(() =>
    promotionalText ? EditorState.createWithContent(convertFromHTML(promotionalText)) :
      EditorState.createEmpty()
  );

  const [rulesEditorState, setRulesEditorState] = useState(() =>
    rules ? EditorState.createWithContent(convertFromHTML(rules)) :
      EditorState.createEmpty()
  );



  const [theCommunity, setCommunity] = useState({
    slug: '',
    name: '',
    description: '',
    promotional_text: '',
    rules: '',
    contact_email: '',
    event_tags: [],
    space_limit: 0,
    image: '',
    background: '',
    status: '',
    default_status: 'AC',
    default_role: 'PA',
  })
  const [image, setImage] = useState(theCommunity?.image ? `${API_BASE_URL}${theCommunity?.image}` : noAvatar);
  const [background, setBackground] = useState(theCommunity?.background ? `${API_BASE_URL}${theCommunity?.background}` : noAvatar);
  const navigate = useNavigate()

  const handleChange = (community, newValue) => {
    setValue(newValue);
  };

  function addCommunity() {
    console.log(community)
    if (community?.slug) {
      updateCommunity(theCommunity).then(r => console.log(r))
        .catch(error => console.log('The error is -> ', error))
    } else {
      saveCommunity(theCommunity).then(r => console.log(r))
        .catch(error => console.log('The error is -> ', error))
    }

  }


  const handleDefaultRole = (community) => {
    setCommunity({ ...theCommunity, default_role: community.target.value })
  };

  const handleDefaultStatus = (community) => {
    setCommunity({ ...theCommunity, default_status: community.target.value })
  };

  const handleTags = (community) => {
    console.log(community.target.value)
    setCommunity({ ...theCommunity, event_tags: community.target.value })
  };


  const handleUpload = () => {
    uploadCommunityImage(theCommunity.slug, selectedFile, 'image').then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleUploadBackground = () => {
    uploadCommunityImage(theCommunity.slug, selectedBackground, 'background').then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleFileChange = (community) => {
    const file = community.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (community) => {
        setSelectedFile({
          file: file,
          imageUrl: community.target.result,
        });
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
    setSelectedFile(file);
  };

  const handleBackgroundChange = (community) => {
    const file = community.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (community) => {
        setSelectedBackground({
          file: file,
          imageUrl: community.target.result,
        });
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBackground(reader.result);
      };
    }
    setSelectedBackground(file);
  };

  const openFileInput = () => {
    document.getElementById('imageInput').click();
  };


  // Handle change for promotionalText editor
  const handlePromotionalTextEditorChange = (newEditorState) => {
    setPromotionalTextEditorState(newEditorState);
    setPromotionalText(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  // Handle change for rules editor
  const handleRulesEditorChange = (newEditorState) => {
    setRulesEditorState(newEditorState);
    setRules(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  useEffect(() => {
    if (selectedFile !== null) {
      handleUpload()
    }
  }, [selectedFile])

  useEffect(() => {
    if (selectedBackground !== null) {
      handleUploadBackground()
    }
  }, [selectedBackground])

  useEffect(() => {
    setCommunity({ ...theCommunity, description })
  }, [description])

  useEffect(() => {
    const htmlPromotionalText = convertToHTML(promotionalTextEditorState.getCurrentContent())
    console.log(htmlPromotionalText)
    setCommunity({ ...theCommunity, promotional_text: htmlPromotionalText })
  }, [promotionalText])

  useEffect(() => {
    const htmlRules = convertToHTML(rulesEditorState.getCurrentContent())
    console.log(htmlRules)
    setCommunity({ ...theCommunity, rules: htmlRules })
  }, [rules])


  useEffect(() => {
    //getCommunityInfo(true)
  }, [])

  useEffect(() => {
    console.log("loading community ", community)
    if (community !== null) {

      console.log(tags?.map((tag) => tag.name))
      const selectedTags = tags?.map((tag) => tag.name)
      console.log(community?.group)


      setCommunity({
        slug: community?.slug,
        name: community?.name,
        description: community?.description,
        promotional_text: community?.promotional_text,
        rules: community?.rules,
        contact_email: community?.contact_email,
        event_tags: selectedTags,
        space_limit: community?.space_limit,
        image: community?.image,
        background: community?.background,
        status: community?.status,
        default_status: community?.default_status,
        default_role: community?.default_role,

      })
      setDescription(community?.description)
      setPromotionalText(community?.promotional_text)
      setRules(community?.rules)
      

      if (community?.promotional_text) {
        const blocksFromHTML = convertFromHTML(community?.promotional_text);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorState = EditorState.createWithContent(state);
        setPromotionalTextEditorState(editorState)
      }

      if (community?.rules) {
        const blocksFromHTML = convertFromHTML(community?.rules);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorState = EditorState.createWithContent(state);
        setRulesEditorState(editorState)
      }
    }

  }, [community])


  useEffect(() => {
    if (theCommunity.community !== '') {
      getEventTags(theCommunity.community).then(r => {
        setTags(r.tags)
      }).catch(error => console.log('The error is -> ', error))
    }
  }, [theCommunity.community])

  // Populate the Instructiosn Editor the first time

  const avatar = noAvatar
  return (
    <Container>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="Name" value={theCommunity?.name} onChange={(e) => setCommunity({ ...theCommunity, name: e.target.value })} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField label="Contact email" value={theCommunity?.contact_email} onChange={(e) => setCommunity({ ...theCommunity, contact_email: e.target.value })} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                label="Max members"
                type="number"
                value={theCommunity.space_limit}
                onChange={(e) => setCommunity({ ...theCommunity, space_limit: e.target.value })}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 1,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="default-status-label">Default Status</InputLabel>
              <Select
                labelId="default-status-label"
                value={theCommunity?.default_status}
                onChange={handleDefaultStatus}
                inputProps={{ 'aria-label': 'Default Status' }}
                style={{ width: '100%' }}
              >
                <MenuItem value='AC'>Active</MenuItem>
                <MenuItem value='PP'>Pending</MenuItem>
                <MenuItem value='IN'>Inactive</MenuItem>
                <MenuItem value='RE'>Rejected</MenuItem>
                <MenuItem value='BA'>Banned</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="default-role-label">Default Role</InputLabel>
              <Select
                labelId="default-role-label"
                value={theCommunity?.default_role}
                onChange={handleDefaultRole}
                inputProps={{ 'aria-label': 'Default Role' }}
                style={{ width: '100%' }}
              >
                <MenuItem value='FO'>Founder</MenuItem>
                <MenuItem value='AD'>Admin</MenuItem>
                <MenuItem value='MA'>Manager</MenuItem>
                <MenuItem value='PA'>Participant</MenuItem>
                <MenuItem value='GU'>Guest</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

            <FormControl style={{ width: '100%' }}>
              <InputLabel id="group-tags-label">Default Role</InputLabel>
              <Select
                labelId="group-tags-label"
                multiple
                value={theCommunity?.event_tags}
                onChange={handleTags}
                displayEmpty
                inputProps={{ 'aria-label': 'Community' }}
              >
                <MenuItem value="" disabled>Select a tag</MenuItem>
                {tags?.map((item, index) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                label="Brief Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: '40px 0' }} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
            <InputLabel id="promotional-text-label">Promotional Text</InputLabel>
            <Editor
              labelId="promotional-text-label"
              editorState={promotionalTextEditorState}
              onEditorStateChange={handlePromotionalTextEditorChange}
              editorStyle={{
                border: '1px solid #ced4da',
                padding: '10px',
                borderRadius: '4px',
                minHeight: '300px', // Fixed height
                maxHeight: '300px', // Fixed height
                overflowY: 'auto',
              }}
            />

          </Grid>

          <Grid item xs={12} sm={12} >
            <InputLabel id="rules-label">Rules</InputLabel>

            <Editor
              labelId="rules-label"
              editorState={rulesEditorState}
              onEditorStateChange={handleRulesEditorChange}
              editorStyle={{
                border: '1px solid #ced4da',
                padding: '10px',
                borderRadius: '4px',
                minHeight: '300px', // Fixed height
                maxHeight: '300px', // Fixed height
                overflowY: 'auto',
              }}
            />

          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="imageInput"
                type="file"
                onChange={handleFileChange}
              />
              <ImageContainer>
                <Avatar
                  src={avatar}
                  alt="Avatar"
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '50%' }}
                  onClick={openFileInput}
                />
              </ImageContainer>
              <Typography variant="caption" display="block" gutterBottom>
                Upload community image
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="backgroundInput"
                type="file"
                onChange={handleBackgroundChange}
              />
              <ImageContainer>
                <Avatar
                  src={avatar}
                  alt="Avatar"
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '50%' }}
                  onClick={openFileInput}
                />
              </ImageContainer>
              <Typography variant="caption" display="block" gutterBottom>
                Upload community background
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="extended" color="primary" aria-label="add" style={{ width: '60%' }} onClick={() => addCommunity()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

CommunityForm.propTypes = {
  community: null
}

export default CommunityForm
