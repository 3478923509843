import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CircularProgress, Grid, Select, MenuItem, TextField, Button, Modal, Typography, Box, InputLabel } from '@mui/material'
import MemberListItem from '../memberListItem/view'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'
import CampaignHeader from './header'
import { ModalContent, ModalHeader } from './styled'
import { use } from 'i18next'
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getCampaigns, createCampaign } from 'api'
import { get } from 'api/utils'
import dayjs, { Dayjs } from 'dayjs';
import People from '@mui/icons-material/People';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';

const CampaignList = ({ slug }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [campaigns, setCampaigns] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(['PA'])
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    privacyPolicyChecked: false
  })
  const [theCampaign, setCampaign] = useState({})
  const [messageEditorState, setMessageEditorState] = useState(EditorState.createEmpty())

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
    inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'] },
    list: { inDropdown: false, options: ['unordered', 'ordered'] },
    textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
    history: { inDropdown: false, options: ['undo', 'redo'] },
  };



  function handleInputChange(e) {
    const { name, value } = e.target
    if (name === 'subject') {
      setSubject(value)
      setCampaign({ ...theCampaign, subject: value })
    }
    if (name === 'message') {
      setMessage(value)
    }
  }



  function doCampaign(status) {
    const data = {
      group: slug,
      target: selectedOption,
      subject: subject,
      body: message,
      status: status
    }
    createCampaign(data).then(r => {
      console.log('Campaign created', r)
      fetchCampaigns()
      setConfirmModal(false)
    })
    setSelectedOption([''])
    setSubject('')
    setMessage('')
  }

  // Handle change for rules editor
  const handleMessageEditorChange = (newEditorState) => {
    setMessageEditorState(newEditorState);
    setMessage(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value)
    setCampaign({ ...theCampaign, type: value })
  }

  function fetchCampaigns() {
    getCampaigns(slug).then(r => {
      setCampaigns(r?.campaigns)
    })
  }

  useEffect(() => {
    fetchCampaigns()    
  }, [])


  useEffect(() => {
    const htmlMessage = convertToHTML(messageEditorState.getCurrentContent())
    setCampaign({ ...theCampaign, message: htmlMessage })
  }, [message])

  const roleMapping = {
    'FO': 'Founder',
    'AD': 'Admin',
    'MA': 'Manager',
    'PA': 'Participant',
    'GU': 'Guest',
  };

  console.log('Campaigns', campaigns)
  return (
    <Container>
      <CampaignHeader openModal={() => setConfirmModal(true)} />
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          {campaigns?.length === 0 && (
            <NoResultsPlaceholder firstSentence={t('No campaigns found')} lastSentence={t('Create a new campaign now')} />
          )}
          {campaigns?.length > 0 && (
            <Box sx={{ marginBottom: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={1}><Typography variant="body1">{t('Type')}</Typography></Grid>
                <Grid item xs={1}><Typography variant="body1">{t('Target')}</Typography></Grid>
                <Grid item xs={2}><Typography variant="body1">{t('Status')}</Typography></Grid>
                <Grid item xs={2}><Typography variant="body1">{t('Planned Sent')}</Typography></Grid>
                <Grid item xs={2}><Typography variant="body1">{t('Destinataries')}</Typography></Grid>
                <Grid item xs={4}><Typography variant="body1">{t('Subject')}</Typography></Grid>
              </Grid>
            </Box>
          )}
          {campaigns?.map((item, index) => {
            const formattedDate = dayjs(item?.planned_sent)?.format('DD/MM/YYYY HH:mm');
            let targetArray = [];
            let target = ""
            if (item?.target) {
              try {
                targetArray = JSON.parse(item.target.replace(/'/g, '"')); // Convert string to array
                target = targetArray?.map(role => roleMapping[role]?.substring(0,2))?.join(', ');
              } catch (error) {
                console.error('Error parsing target:', error);
                target = roleMapping[item.target]
              }
            }
            
            return (
              <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
                <Grid item xs={1}><Typography variant="body2">{t(`campaign.campaignType.${item?.type}`)}</Typography></Grid>
                <Grid item xs={1}><Typography variant="body2">{t(target)}</Typography></Grid>
                <Grid item xs={2}><Typography variant="body2">{t(`campaign.campaignStatus.${item?.status}`)}</Typography></Grid>
                <Grid item xs={2}><Typography variant="body2">{formattedDate}</Typography></Grid>
                <Grid item xs={2}>
                  <Box display="flex" alignItems="center">
                    <People sx={{fontSize:'18px'}} color="primary" />
                    <Typography variant="body2" sx={{ marginLeft: 0.5 }}>{item.recipients.total}</Typography>
                    <CheckCircle color="success" sx={{ marginLeft: 2, fontSize:'18px' }} />
                    <Typography variant="body2" sx={{ marginLeft: 0.5 }}>{item.recipients.sent}</Typography>
                    <Error color="error" sx={{ marginLeft: 2, fontSize:'18px' }} />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>{item.recipients.bounce}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ overflow: 'ellipsis' }}><Typography variant="body2">{item?.subject}</Typography></Grid>
              </Grid>
            )
          }
          )}
        </Grid>
      </Grid>
      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>

          <Typography id="parent-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Send an email campaign to the community
          </Typography>
          <ModalHeader>
            <Grid item xs={12} md={2}>
              <Select
                value={selectedOption}
                onChange={(e) => handleOptionChange(e.target.value)}
                fullWidth
                size='small'
                multiple
              >
                <MenuItem value="AD">Admin</MenuItem>
                <MenuItem value="PA">Participants</MenuItem>
                <MenuItem value="GU">Guests</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                label="Subject"
                size='small'
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="subject"
                value={subject}
                onChange={handleInputChange}
              />
            </Grid>
          </ModalHeader>
          <Grid item xs={12} sm={12} >
            <InputLabel id="rules-label">Message</InputLabel>

            <Editor
              labelId="rules-label"
              editorState={messageEditorState}
              onEditorStateChange={handleMessageEditorChange}
              toolbar={toolbarOptions}
              editorStyle={{
                border: '1px solid #ced4da',
                padding: '10px',
                borderRadius: '4px',
                minHeight: '300px', // Fixed height
                maxHeight: '300px', // Fixed height
                overflowY: 'auto',
              }}
            />

          </Grid>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button size='small' variant='contained' color='secondary' onClick={() => doCampaign('PE')} sx={{ alignItems: 'center' }}>
              Send Campaign
            </Button>
            <Button size='small' variant='contained' color='secondary' onClick={() => doCampaign('DR')} sx={{ alignItems: 'center' }}>
              Save for later
            </Button>
          </div>





        </ModalContent>
      </Modal>
    </Container>
  )
}

CampaignList.propTypes = {

}

export default CampaignList
