import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { Button, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container, HomeContent, SectionHead, HeroBoxSubtitle } from './styled'
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Card, CardMedia, CardContent, Link, ListItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderToolbar from '../../../organisms/headerToolbar'
import HomeHeader from '../homeHeader/view'
import ProfileSingle from 'components/organisms/account/ProfileSingle'
import { Parallax, useParallax } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import coworking from 'assets/images/coworking.jpg'
import home_community from 'assets/images/home_community.jpg'
import home_events from 'assets/images/home_events.jpg'
import home_spaces from 'assets/images/home_spaces.jpg'
import parallax from 'assets/images/parallax.jpg'
import about_us from 'assets/images/about_us_d_p.jpg'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import PublicNavBar from 'components/organisms/publicNavBar/view'

const RigthActions = ({ pathname, t, reloadData }) => {
  const [modalStaff, setModalStaff] = useState(false)
  const [modalMSPlan, setModalMSPlan] = useState(false)

  function handleStaffClose() {
    setModalStaff(false)
    reloadData(true)
  }

  function handleMSClose() {
    setModalMSPlan(false)
  }

}

RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  //handleClickOnLink: PropTypes.func.isRequired,
}

const ContactUsView = ({ permitedUrls, ...props }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [reloadData, setReloadData] = useState(false)

  // tabs
  const [staffData, setStaffData] = useState(null)
  const permited = []

  const spaces = [
    {
      name: 'Space 1',
      address: 'Address 1',
      image: coworking,
    },
    {
      name: 'Space 2',
      address: 'Address 2',
      image: coworking,
    },
  ]

  const faqItems = [
    {
      question: 'What is Epic Spaces?',
      answer: 'Epic Spaces is a platform that seamlessly integrates vibrant communities, genuine connections, and collaborative environments. It allows you to create and manage communities, host events, and unlock unique working spaces.'
    },
    {
      question: 'How can I book a space?',
      answer: 'We are still on Beta testing, but we have a bunch of awesome places in London where you can meet our community. Please get in touch ar hello@the-epic.space!'
    },
    {
      question: 'How can I create a community?',
      answer: 'To create a community, sign in to your account, go to the dashboard, and follow the steps to create a new community. Define your purpose, set your goals, and watch your community grow.'
    },
    // Add more FAQ items as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const title = 'Discover, Connect, Thrive: Your Epic Community Hub'

  return (
    <Container>
      <PublicNavBar />
      <HomeContent>
        <Box sx={{ padding: '80px 20px' }}>
          <Grid container spacing={4} sx={{ marginBottom: '2vh' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{
                textAlign: 'left',
                color: 'title.main',
              }}>
                About Us
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} sx={{ marginBottom: '10vh' }}>
            <Grid item xs={12} md={6}>

              <Typography variant="body1" paragraph>
                Welcome to <b>Epic Spaces</b>, where communities come together to thrive. Founded by Pedro, a seasoned CTO with a passion for revolutionizing flexible working environments, and David, a visionary with over two decades of experience fostering communities focused on green energy and Sustainable Development Goals (SDGs), Epic Spaces is more than just a platform—it's a catalyst for collaboration and innovation.
              </Typography>
              <Typography variant="body1" paragraph>
                With Pedro's expertise in flexible working spaces and David's dedication to nurturing sustainable communities, Epic Spaces is committed to providing tailored solutions that empower groups to discover the perfect environment for their endeavors. Whether you're a remote team seeking a dynamic workspace or a passionate group looking to host an impactful event, Epic Spaces is here to help you unlock the potential of your community. Join us on our mission to create spaces where connections flourish, ideas spark, and progress thrives. Welcome to Epic Spaces—where every space tells a story of collaboration, growth, and impact.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* You can add an image or illustration here */}
              <Box sx={{ width: '100%', height: '100%' }}>
                {/* Replace 'aboutUsImage.jpg' with your actual image file */}
                <img
                  src={about_us}
                  alt="About Us"
                  style={{ width: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
          </Grid>




        </Box>
      </HomeContent>

      <Box
        component="footer"
        sx={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          marginTop: 'auto', // Push the footer to the bottom
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: hello@the-epic.space
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Link href="/privacy-policy" color="text.primary" sx={{ mr: 2 }}>
            Privacy Policy
          </Link>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Epic Spaces Ltd. All rights reserved.
          </Typography>
          <a href="https://www.vecteezy.com/free-vector/community">Community Vectors by Vecteezy</a>
        </Box>
      </Box>
    </Container >
  )
}

ContactUsView.propTypes = {
  handleClickOnLink: PropTypes.func.isRequired,
  permitedUrls: PropTypes.object,
}

ContactUsView.defaultProps = {
  permitedUrls: {},
}

export default ContactUsView
