/* eslint-disable eol-last */
import axios from 'axios'
import qs from 'qs'
import { API_BASE_URL } from '../config/api'
import { configureAxiosDefaults, get, post, postImage, put, putImage, remove, removeAxiosTokenHeader } from './utils'

export function configureToken(token) {
  configureAxiosDefaults('Authorization', token ? `Bearer ${token}` : null)
}

export const refreshToken = refresh => {
  const url = `${API_BASE_URL}/auth/jwt/refresh/`
  return axios.post(url, { refresh })
}

export function removeAxiosToken() {
  removeAxiosTokenHeader()
}

export const getUserToken = data => {
  const url = '/auth/jwt/create/'
  return post(url, data)
}

export const signUp = data => {
  const url = '/auth/users/'
  return post(url, data)
}

export const resetPassword = data => {
  const url = '/api/request-password-reset/'
  return post(url, data)
}

export const validateAccount = data => {
  const url = '/api/validate-account/'
  return post(url, data)
}

export const confirmPassword = data => {
  const url = '/api/password-reset/'
  return post(url, data)
}


export const getMe = () => {
  const url = '/auth/users/me/'
  return get(url)
}

export const getProfile = () => {
  const url = `/api/me/`
  return get(url)
}

export const setTheme = data => {
  const url = '/api/themes/'
  return postImage(url, data)
}

export const getTheme = () => {
  const url = '/api/themes/'
  return get(url)
}


// DASHBOARD

export const getDashboard = (measure, params) => {
  const { club, period, date } = params
  const noDateParams = {
    club,
    period,
  }
  const dateParams = {
    club,
    period,
    start: date?.start,
    end: date?.end,
  }
  let url = ''
  if (params.period === 'custom' && params.date !== '') {
    url = `/api/dashboard/${measure}?${qs.stringify(dateParams, { skipNulls: true })}`
  } else {
    url = `/api/dashboard/${measure}?${qs.stringify(noDateParams, { skipNulls: true })}`
  }
  return get(url)
}




//  EVENTS

export const getEvents = () => {
  const url = '/api/events/'
  return get(url)
}

export const setEvent = data => {
  const url = '/api/events/'
  return post(url, data)
}

export const updateEvent = data => {
  const url = `/api/events/${data?.slug}`  
  return put(url, data)
}


export const setEvents = data => {
  const url = '/api/events-list/'
  return post(url, data)
}


export const getEvent = (slug) => {
  const url = `/api/event-info/${slug}`
  return get(url)
}

export const getPublicEvent = (slug) => {
  const url = `/api/public/event-info/${slug}`
  return get(url)
}

export const getEventTicket = (slug) => {
  const url = `/api/event-ticket/${slug}`
  return get(url)
}

export const getPublicEventTicket = (slug) => {
  //const url = `/api/public/event-ticket/${slug}`
  const url = `/api/event-ticket/${slug}`
  return get(url)
}

export const joinEvent = data => {
  const url = '/api/event-ticket/'
  return post(url, data)
}


export const updateEventTicket = (slug,data) => {
  const url = `/api/event-ticket/${slug}`
  return put(url, data)
}

export const uploadEventTicketAvatar = (slug, photo) => {

  const url = `/api/event-ticket/${slug}`
  const form = new FormData();
  form.append('avatar', photo);

  return putImage(url, form)
}

export const uploadEventImage = (slug, photo, target) => {

  const url = `/api/events/${slug}`
  const form = new FormData();
  form.append(target, photo);

  return putImage(url, form)
}

export const uploadEventBackground = (slug, photo) => {

  const url = `/api/events/${slug}`
  const form = new FormData();
  form.append('background', photo);

  return putImage(url, form)
}


// Community and groups
export const getEventTags = (group) => {

  const params = {
    group,
  }
  let url =  `/api/event-tags/?${qs.stringify(params, { skipNulls: true })}`
  return get(url)
}

export const eventTicketAction = data => {
  const url = '/api/event-ticket-action/'
  return post(url, data)
}

export const validateTicket =(slug, data) => {
  const url = `/api/event-validate-ticket/${slug}`
  return post(url, data)
}

// Profile

export const updateProfile = data => {
  const url = '/api/me/'
  return put(url, data)
}

// Community and groups
export const getCommunity = (slug) => {
  const url = `/api/community/${slug}`
  return get(url)
}

// Community and groups
export const getCommunityPublic = (slug) => {
  const url = `/api/public/community/${slug}`
  return get(url)
}

// Community and groups
export const getCommunities = () => {
  const url = `/api/communities/`
  return get(url)
}

// Community and groups
export const getRecommendedCommunities = () => {
  const url = `/api/communities-recommended/`
  return get(url)
}



// Community and groups
export const getCommunitiesForMember = () => {
  const url = `/api/communities-member/`
  return get(url)
}

export const joinCommunity = data => {
  const url = '/api/community-join/'
  return post(url, data)
}

export const joinCommunityGuest = data => {
  const url = '/api/community-join-guest/'
  return post(url, data)
}


// Community and groups
export const getCommunityActivity = (slug, params) => {
  const url = `/api/community-activity/${slug}?${qs.stringify(params, { skipNulls: true })}`
  return get(url);
}

export const setCommunity = data => {
  const url = `/api/groups/`  
  return post(url, data)
}


export const updateCommunity = data => {
  const url = `/api/community/${data?.slug}`  
  return put(url, data)
}

export const uploadCommunityImage = (slug, photo, target) => {

  const url = `/api/community/${slug}`
  const form = new FormData();
  form.append(target, photo);

  return putImage(url, form)
}

export const uploadCommunityBackground = (slug, photo) => {

  const url = `/api/community/${slug}`
  const form = new FormData();
  form.append('background', photo);

  return putImage(url, form)
}

export const uploadProfileImage = (target, photo) => {

  const url = `/api/me/`
  const form = new FormData();
  form.append(target, photo);

  return putImage(url, form)
}


// Community and groups
export const searchCommunities = (params) => {
  const url = `/api/communities/?${qs.stringify(params, { skipNulls: true })}`
  //const url = `/api/communities/`
  return get(url)
}


// Community and groups
export const getVenues = () => {
  const url = `/api/venues/`
  return get(url)
}

// Community and groups
export const getVenuesGroup = (slug) => {
  const url = `/api/venues-group/${slug}`
  return get(url)
}


// Venue
export const getVenue = (slug) => {
  const url = `/api/venue-info/${slug}`
  return get(url)
}

export const getVenuePrice = (slug, params) => {
  const url = `/api/venue-prices/${slug}?${qs.stringify(params, { skipNulls: true })}`
  return get(url)
}

// Campaigns
// Community and groups
export const getCampaigns = () => {
  const url = `/api/campaigns/`
  return get(url)
}

export const createCampaign = data => {
  const url = '/api/campaigns/'
  return post(url, data)
}


// Bookings


export const createBooking = data => {
  const url = '/api/bookings/'
  return post(url, data)
}

export const createBookingPlan = data => {
  const url = '/api/booking-plans/'
  return post(url, data)
}


export const joinBooking = data => {
  const url = '/api/join-bookings/'
  return post(url, data)
}

export const validateBookingPayment = (sessionId) => {
  const url = `/purchase-success/${sessionId}`
  return get(url)
}

export const getBookings = (params) => {
  const getParams = {
    status: params.status,
    venue: params.venue,
    code: params.code,
    date: params.date,
    order_by: params.order_by,
    order_dir: params.order_dir,
    page_size: params.page_size,
    page_number: params.page_number,
  };
  
  const url = `/api/bookings/?${qs.stringify(getParams, { skipNulls: true })}`;
  return get(url);
};

export const getBooking = (slug) => {
  const url = `/api/bookings/${slug}`
  return get(url)
}

export const getGroupBooking = (slug) => {
  const url = `/api/booking-plan/${slug}`
  return get(url)
}

export const getSearchMap = (params) => {
  const getParams = {
    latitude: params.latitude,
    longitude: params.longitude,
    start: params.start,
    end: params.end,
    spaces: params.spaces,
    order_dir: params.order_dir,
    page_size: params.page_size,
    page_number: params.page_number,
  };

  const url = `/api/search-map/?${qs.stringify(getParams, { skipNulls: true })}`;
  return get(url)
}


export const getAvailableGroupBooking = (params) => {
  const { group, venue, booking } = params
  const getParams = {
    group,
    venue,
    booking,
  } 
  const url = `/api/group-available-bookings/?${qs.stringify(getParams, { skipNulls: true })}`
  return get(url)
}

export const acceptBooking = data => {
  const url = '/api/accept-booking/'
  return post(url, data)
}

export const getBookingPlans = (params) => {
  const url = `/api/booking-plans/`
  return get(url)
}